import { Suspense } from "react";
import { ComponentsE } from "App";
import * as components from "@components/pages";
import PageLoader from "@components/PageLoader/PageLoader";

export const SuspendComponent = (
  Component: ComponentsE,
  params?: { [key: string]: boolean }
): JSX.Element => {
  const useComponent = (c: ComponentsE): JSX.Element => {
    const Component = components.default[c];
    return <Component />;
  };
  return (
    <Suspense fallback={<PageLoader />}>{useComponent(Component)}</Suspense>
  );
};
