import { AxiosResponse } from "axios";
import { GetRequest, PostRequest } from "./http/BaseRequest";
import { AccountResponse } from "./http/responses/AccountResponse";
import service from "./http/service";
import { ServerResponse } from "./http/settings";

export enum MiningPlanDaysE {
  MON = "Mon",
  TUE = "Tue",
  WED = "Wed",
  THU = "Thu",
  FRI = "Fri",
  SAT = "Sat",
  SUN = "Sun",
}

export const miningDaysArr = Object.values(MiningPlanDaysE);

/*
interface ILoginParams {
  email: string;
  password: string;
  recaptcha: string;
} */
/*
export const login = ({
  email,
  password,
  recaptcha,
}: ILoginParams): Promise<AxiosResponse<AccountResponse> | Error> => {
  return service.api.post("/logon", {
    email,
    password,
    "g-recaptcha-response": recaptcha,
  });
};
*/

export interface ForgotResI extends ServerResponse {
  Record: string;
}
/*
export const forgot = (
  email: string,
  recaptcha: string
): Promise<AxiosResponse<ForgotResI> | Error> => {
  return service.api.post("/user/forgot", {
    email,
    "g-recaptcha-response": recaptcha,
  });
};
*/
export class UserForgotRequest extends PostRequest {
  constructor(
    email: string,
    recaptcha: string
  ) {
    super("/user/forgot", {
      email,
      "g-recaptcha-response": recaptcha,
    })
  }
}

/*
export const verify = (
  email: string,
  code: string
): Promise<AxiosResponse<ServerResponse> | Error> => {
  return service.api.post("/user/verify", {
    email,
    code,
  });
}; */
/*
export const restore = (
  password: string,
  repeat_password: string,
  recaptcha: string,
  email: string,
  code: string
): Promise<AxiosResponse<ServerResponse> | Error> => {
  return service.api.post("/user/restore", {
    password,
    repeat_password,
    "g-recaptcha-response": recaptcha,
    email,
    code,
  });
};
*/

export class UserVerifyRequest extends PostRequest {
  constructor(
    email: string,
    code: string
  ) {
    super("/user/verify", {
      email,
      code,
    })
  }
}
export class UserRestoreRequest extends PostRequest {
  constructor(  
    password: string,
    repeat_password: string,
    recaptcha: string,
    email: string,
    code: string
  ) {
    super("/user/restore", {
      password,
      repeat_password,
      "g-recaptcha-response": recaptcha,
      email,
      code,
    })
  }
}

/*
export const getAccount = (email?: string): Promise<AxiosResponse<AccountResponse> | Error> => {
  return service.api.post("/user/account", { email });
};
*/
/*
export const loginWithTwoFa = (
  Code2FA?: string
): Promise<AxiosResponse<AccountResponse> | Error> => {
  return service.api.post("/complete2fa", {
    Code2FA,
  });
};
*/
// for cancellable request v2
export class LoginWith2FaRequest extends PostRequest {
  constructor(code : string) {
    super("/complete2fa", { Code2FA : code });
  }
}

export class LogoffRequest extends GetRequest {
  constructor() {
    super("/logoff")
  }
}
//export const logoff = (): Promise<AxiosResponse<ServerResponse> | Error> => {
//  return service.api.get("/logoff");
//};