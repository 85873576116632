import { AxiosResponse } from "axios";
import { PostRequest } from "./http/BaseRequest";
import service from "./http/service";
import { ServerResponse } from "./http/settings";

export interface UserI {
  id: string;
  email: string;
  name?: string;
}
export interface UserAditional extends UserI {
  "2fa": boolean;
  m_online: number; // count miners online
  m_offline: number; // count miners offline
  m_low: number; // count miners low
  speed: number; // total speed all usr miners
  link: string;
  is_admin?: boolean;
}

export interface UsersResponseI extends ServerResponse {
  Records: UserAditional[];
  Total: number;
}
/*
export const getUsers = (
  offset = 0,
  limit = 20
): Promise<AxiosResponse<UsersResponseI> | Error> => {
  return service.api.post("/admin/user/list", { offset, limit });
};
*/
export class GetUsersRequests extends PostRequest {
  constructor(
    offset = 0,
    limit = 20
  ) {
    super("/admin/user/list", { offset, limit });
  }
}

export interface AllUsersResponseI extends ServerResponse {
  Record: UserI[];
}
/*
export const getAllUsers = (): Promise<
  AxiosResponse<AllUsersResponseI> | Error
> => {
  return service.api.post("/admin/user/list/all");
};
*/

export class GetAllUsersRequest extends PostRequest {
  constructor() {
    super("/admin/user/list/all", {});
  }
}