import { AppDispatch } from "@store";
import {
  ActionBaseMinerTypes,
  ActionSetMinerTypes,
  ActionTypesMinerTypesE,
} from "./actionTypes";

export function setAllMinerTypes(types: string[]): ActionSetMinerTypes {
  return {
    type: ActionTypesMinerTypesE.SET_ALL_MINER_TYPES,
    payload: {
      miner_types: types,
    },
  };
}

export function setMinerTypes(types: string[]): ActionSetMinerTypes {
  return {
    type: ActionTypesMinerTypesE.SET_MINER_TYPES,
    payload: {
      miner_types: types,
    },
  };
}

export function clearMinerTypes(): ActionBaseMinerTypes {
  return {
    type: ActionTypesMinerTypesE.CLEAR_MINER_TYPES,
  };
}

export function getAllTypes(miner_types: string[]) {
  return async (dispatch: AppDispatch) => {
    let devices: any[] = miner_types;
    dispatch(setMinerTypes(devices));
    // if (!getDevices(true).length) {
    //   const allDev = await allDevices();
    //   devices = [...devices, ...allDev.map((item) => item.name)];
    //   dispatch(setAllMinerTypes(devices));
    //   return;
    // }
    // dispatch(setAllMinerTypes([...devices, ...getDevices(true)]));
  };
}
