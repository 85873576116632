export enum ROUTES_NAME_E {
  HOME = "/",
  LOGIN = "/login",
  REGISTER = "/login?signup",
  HOTEL = "/hotel/:name",
  LISTINGS = "/listings",
  MESSENGER = "/messenger",
  REQUESTBOOK = "/requestBook/:book",
  ACCOUNT = "/account/:user",
  POOL_SETTINGS = "/pool_settings",
  DASHBOARD = "/dashboard",
  CLIENTS = "/clients",
}
