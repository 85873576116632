import Lottie from "lottie-react";
import animation from "./animation.json";
import styles from "./AnimationLoader.module.scss";
import { IAnimationLoaderProps } from "./AnimationLoader.props";
import cn from "classnames";

export default function AnimationLoader({ className }: IAnimationLoaderProps) {
  return (
    <Lottie
      animationData={animation}
      className={cn(styles.loader, className)}
    />
  );
}
