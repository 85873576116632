import { TgAccountsI } from "@services/getTgAccounts";
import {
  IAccount,
  NotificationsEmails,
} from "@services/http/responses/AccountResponse";

export enum ActionTypesMeE {
  SET_ME = "SET_ME",
  UPDATE_ME = "UPDATE_ME",
  SET_POOL_SETTINGS = "SET_POOL_SETTINGS",
  SET_POOL_DIVIDER = "SET_POOL_DIVIDER",
  SET_USERS = "SET_USERS",
  CLEAR_ME = "CLEAR_ME",
  SET_NOTIFS_EMAILS = "SET_NOTIFS_EMAILS",
  SET_TG_ACCOUNTS = "SET_TG_ACCOUNTS",
}

export interface PoolDivider {
  name: string;
}

export interface ActionBaseMe {
  type: ActionTypesMeE;
}

export interface ActionSetMe extends ActionBaseMe {
  payload: {
    accData: IAccount;
  };
}
export interface ActionUpdateMe extends ActionBaseMe {
  payload: {
    accData: IAccount;
  };
}

// export interface ActionSetPoolSettings extends ActionBaseMe {
//   payload: {
//     poolSettings: IStoredSettings[];
//   };
// }

export interface ActionSetPoolDivider extends ActionBaseMe {
  payload: {
    poolDivider: PoolDivider[];
  };
}

export interface ActionSetNotifsEmails extends ActionBaseMe {
  payload: {
    emails: NotificationsEmails[];
  };
}

export interface ActionSetTgAccounts extends ActionBaseMe {
  payload: {
    tgAccounts: TgAccountsI[];
  };
}
