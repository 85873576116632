import { HashAlgosE } from "@utils/HashAlgos";
import {
  ActionAddPoolSetting,
  ActionBasePoolSettings,
  ActionDeletePoolSetting,
  ActionEditPoolSetting,
  ActionSetPoolSettings,
  ActionTypePoolSettings,
  IPoolSetting,
} from "./actionTypes";
import { IS_DEBUG } from "../../config";
import { DEFAULT_POOL_ID } from "@components/pages/PoolSettings/usePoolSettings.control";

const mock: IPoolSetting[] = [
  {
    id: "123123",
    name: "Pool setting #1",
    hash: HashAlgosE.ETH,
    address1: "address 1",
    login1: "login 1",
  },
  {
    id: "asdfasfasf",
    name: "Pool setting #2",
    hash: HashAlgosE.SCRYPT,
    address1: "address sdsdfg",
    login1: "login sdfgsddgsdg",
    password1: "123sdfsdfg348t9",
  },
  {
    id: "asdfas123fasf",
    name: "Pool setting #3",
    hash: HashAlgosE.SCRYPT,
    address1: "address123234234 ",
    login1: "login 234234sdfgsddgsdg",
    password1: "123sdfsdfg348t9",
  },
];

const initialStore: { poolSettings: IPoolSetting[] } = {
  poolSettings: IS_DEBUG ? mock : [],
};

const reducer = (store = initialStore, action: ActionBasePoolSettings) => {
  switch (action.type) {
    case ActionTypePoolSettings.SET_POOL_SETTINGS:
      const pools = (action as ActionSetPoolSettings).payload;
      return {
        ...store,
        poolSettings: pools,
      };
    case ActionTypePoolSettings.DELETE_POOL_SETTING:
      const id = (action as ActionDeletePoolSetting).payload;
      const filtered = store.poolSettings.filter((p) => p.id !== id);
      return {
        ...store,
        poolSettings: filtered,
      };
    case ActionTypePoolSettings.ADD_POOL_SETTING:
      const newPool = (action as ActionAddPoolSetting).payload;
      return {
        ...store,
        poolSettings: [...store.poolSettings, newPool],
      };
    case ActionTypePoolSettings.EDIT_POOL_SETTING:
      const editedSetting = (action as ActionEditPoolSetting).payload;
      const findIndex = store.poolSettings.findIndex(
        (s) => s.id === editedSetting.id
      );
      if (findIndex === -1) return { ...store };
      return {
        ...store,
        poolSettings: [
          ...store.poolSettings.slice(0, findIndex),
          editedSetting,
          ...store.poolSettings.slice(findIndex + 1),
        ],
      };
    case ActionTypePoolSettings.EDIT_POOL_NAME:
      const { name, id: poolId } = (action as ActionEditPoolSetting).payload;
      const index = store.poolSettings.findIndex((s) => s.id === poolId);
      const find = store.poolSettings[index];
      if (!find) return { ...store };
      return {
        ...store,
        poolSettings: [
          ...store.poolSettings.slice(0, index),
          { ...find, name },
          ...store.poolSettings.slice(index + 1),
        ],
      };
    default:
      return { ...store };
  }
};

export default reducer;
