import { TgAccountsI } from "@services/getTgAccounts";
import {
  IAccount,
  NotificationsEmails,
} from "@services/http/responses/AccountResponse";
import {
  ActionBaseMe,
  ActionSetMe,
  ActionSetNotifsEmails,
  ActionSetPoolDivider,
  // ActionSetPoolSettings,
  ActionSetTgAccounts,
  ActionTypesMeE,
  ActionUpdateMe,
  PoolDivider,
  // IStoredSettings,
} from "./actionTypes";

export function setMe(accData: IAccount): ActionSetMe {
  return {
    type: ActionTypesMeE.SET_ME,
    payload: { accData },
  };
}

export function updateMe(accData: IAccount): ActionUpdateMe {
  return {
    type: ActionTypesMeE.UPDATE_ME,
    payload: { accData },
  };
}

// export function setPoolSettings(
//   poolSettings: IStoredSettings[]
// ): ActionSetPoolSettings {
//   return {
//     type: ActionTypesMeE.SET_POOL_SETTINGS,
//     payload: {
//       poolSettings: poolSettings.sort((a, b) => a.name.localeCompare(b.name)),
//     },
//   };
// }
// export function setPoolDivider(
//   poolDivider: PoolDivider[]
// ): ActionSetPoolDivider {
//   return {
//     type: ActionTypesMeE.SET_POOL_DIVIDER,
//     payload: {
//       poolDivider,
//     },
//   };
// }
export function clearMe(): ActionBaseMe {
  return {
    type: ActionTypesMeE.CLEAR_ME,
  };
}

// export function setNotifsEmeils(
//   emails: NotificationsEmails[]
// ): ActionSetNotifsEmails {
//   return {
//     type: ActionTypesMeE.SET_NOTIFS_EMAILS,
//     payload: { emails },
//   };
// }

// export function setTgAccounts(tgAccounts: TgAccountsI[]): ActionSetTgAccounts {
//   return {
//     type: ActionTypesMeE.SET_TG_ACCOUNTS,
//     payload: { tgAccounts },
//   };
// }
