import moment from "moment";

const S_KEY = "st";
const T_KEY = "t";
const G_KEY = "gt";

export const getServerTime = () => {
  return Number(sessionStorage.getItem(S_KEY));
};

export const saveServerTimeDelta = (time: string | number) => {
  if (!time) return;
  if (typeof time === "string")
    return sessionStorage.setItem(S_KEY, moment(time).valueOf().toString());
  sessionStorage.setItem(S_KEY, time.toString());
};

export const clearServerTime = () => sessionStorage.removeItem(S_KEY);

export const saveTime = (time: number) => {
  if (time === undefined) return;
  sessionStorage.setItem(T_KEY, time.toString());
};

export const getTime = () => {
  return Number(sessionStorage.getItem(T_KEY));
};

export const saveGlobalTime = (time: number) => {
  if (time === undefined) return;
  sessionStorage.setItem(G_KEY, time.toString());
};

export const getGlobalTime = () => {
  return Number(sessionStorage.getItem(G_KEY));
};
