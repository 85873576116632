import { UserI } from "@services/getUsers";
import { ActionBaseUser, ActionSetUsers, ActionTypesUsersE } from "./actionTypes";

export function setUsers(users: UserI[]): ActionSetUsers {
  return {
    type: ActionTypesUsersE.SET_USERS,
    payload: { users },
  };
}

export function clearUsers(): ActionBaseUser {
  return {
    type: ActionTypesUsersE.CLEAR_USERS,
  };
}
