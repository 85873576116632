import { ErrorType } from "@components/global/Error/Error";
import { AddPoolParams } from "@services/addPoolSettings";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { HashForPoolOnly, IPoolSetting } from "@store/poolSettings/actionTypes";
import {
  addPoolAndDeleteDefault,
  addPoolSetting,
  deletePoolSetting,
  editNamePoolSetting,
  editPoolSetting,
} from "@store/poolSettings/actions";
import { HashAlgosE } from "@utils/HashAlgos";
import { dev } from "@utils/dev";
import { useSettings } from "@utils/hooks/useSettings";
import { useToasts } from "@utils/hooks/useToasts";
import { makeNotifs } from "@utils/makeNotifs";
import { FormEvent, RefObject, useEffect, useRef, useState } from "react";

interface Elems extends HTMLFormControlsCollection {
  [key: string]: any;
}

interface MyForm extends HTMLFormElement {
  readonly elements: Elems;
}

type FormType = FormEvent<MyForm>;

interface IPoolSettingsControl {
  readonly isEdit: boolean;
  readonly poolSettings: IPoolSetting[];
  readonly selectedPool?: IPoolSetting;
  readonly formRef: RefObject<HTMLFormElement>;
  readonly isCreatingNewPool: boolean;
  readonly newPoolError: boolean;
  readonly errors?: ErrorType;

  onCreateNewPoolSetting(): void;
  setFilter(f: HashAlgosE): void;
  setHashForCreate(h: HashForPoolOnly): void;
  onEditHandler(): void;
  onSelectPoolSetting(setting: IPoolSetting): void;
  onGetNewPoolName(setting: IPoolSetting): void;
  onFormControl(e: FormType): void;
  setNewPoolError(e: boolean): void;
  onDelete(): void;
  onCancel(): void;
}

export const DEFAULT_POOL_ID = "new_pool_setting";
export const DEFAULT_POOL_NAME = "Enter new setting";

export const usePoolSettingsControl = (): IPoolSettingsControl => {
  const { errors: errNotifs } = makeNotifs();
  const { error: toastErr } = useToasts();
  const dispatch = useAppDispatch();
  const formRef = useRef<HTMLFormElement>(null);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const allSettings = useSettings();
  const [poolSettings, setPoolSettings] = useState<IPoolSetting[]>(allSettings);
  const [filter, setFilter] = useState<HashForPoolOnly>(HashAlgosE.ALL);
  const [hashForCreate, setHashForCreate] = useState<HashForPoolOnly>(
    HashAlgosE.SHA256
  );

  const [selectedPool, setSelectedPool] = useState<IPoolSetting | undefined>(
    allSettings[0]
  );
  const [poolFormForReset, setPoolFormForReset] = useState<
    IPoolSetting | undefined
  >(allSettings[0]);
  const [newPoolError, setNewPoolError] = useState<boolean>(false);
  const [errors, setErrors] = useState<ErrorType>();

  const isCreatingNewPool = selectedPool?.id.includes(DEFAULT_POOL_ID) || false;

  const newSettingDefault: IPoolSetting = {
    id: DEFAULT_POOL_ID,
    address1: "",
    login1: "",
    hash: hashForCreate,
    name: DEFAULT_POOL_NAME,
  };

  const getSettings = async () =>
    //api get settings
    {};

  const onCreateNewPoolSetting = () => {
    // setPoolSettings((prev) => [...prev, newSettingDefault]);

    const findNewPool = poolSettings.find((s) => s.id === DEFAULT_POOL_ID);

    if (findNewPool)
      return toastErr("You already have a new pool that has not been added");

    dispatch(addPoolSetting(newSettingDefault));
    setSelectedPool(newSettingDefault);
    setIsEdit(true);
  };

  const onFilterSettings = () => {
    if (filter === HashAlgosE.ALL) {
      setPoolSettings(allSettings);
      setSelectedPool(allSettings[0]);
      return;
    }
    const filtered = allSettings.filter((s) => s.hash === filter);
    setPoolSettings(filtered);
    setSelectedPool(filtered[0]);
    setIsEdit(false);
  };

  const onEditHandler = () => {
    if (selectedPool?.name.includes(DEFAULT_POOL_NAME))
      return setNewPoolError(true);
    setNewPoolError(false);

    if (isEdit) {
      formRef.current?.requestSubmit();
      return;
    }
    setIsEdit(true);
  };

  const onSelectPoolSetting = (setting: IPoolSetting) => {
    if (setting.name.includes(DEFAULT_POOL_NAME)) return setNewPoolError(true);
    setNewPoolError(false);
    const find = allSettings.find((s) => s.id === setting.id);
    if (find === undefined)
      return dev.error("Not find pool setting with id = ", setting.id);

    setSelectedPool(find);
    setIsEdit(false);
  };

  const onGetNewPoolName = (setting: IPoolSetting) => {
    if (setting.name.includes(DEFAULT_POOL_NAME)) return setNewPoolError(true);
    setNewPoolError(false);

    const index = poolSettings.findIndex((s) => s.id === setting.id);
    if (index === undefined)
      return console.error("Not find pool setting with id = ", setting.id);

    setPoolSettings([
      ...poolSettings.slice(0, index),
      setting,
      ...poolSettings.slice(index + 1),
    ]);
    const isEdit = setting.id !== DEFAULT_POOL_ID;
    //TODO api await
    // { ...values, edit: isEdit } -- в параметы, а получаем ID
    setSelectedPool({ ...setting, id: "ID_FROM_SERVER" });
    if (!isEdit) {
      dispatch(addPoolAndDeleteDefault({ ...setting, id: "ID_FROM_SERVER" }));
      return;
    }
    dispatch(editNamePoolSetting({ id: setting.id, name: setting.name }));
  };
  const onFormControl = async (e: FormType) => {
    e.preventDefault();

    if (!selectedPool) return dev.error("No selected pool");

    if (selectedPool.name.includes(DEFAULT_POOL_NAME))
      return setNewPoolError(true);
    setNewPoolError(false);

    const elems = e.currentTarget.elements;

    let values: AddPoolParams = {
      name: selectedPool.name,
      address1: "",
      login1: "",
      hash: isCreatingNewPool ? hashForCreate : selectedPool.hash,
    };

    for (const i in elems) {
      const element = elems[i] as HTMLInputElement;
      if (element.id) {
        const elem_v = element.value;
        values = {
          ...values,
          [element.id]: elem_v,
        };
      }
    }
    //if errors
    if (values.address1.length === 0 || values.login1.length === 0) {
      setErrors({ ownError: errNotifs.add_pool_setting__check });
      return;
    }
    //api
    dispatch(editPoolSetting({ ...values, id: selectedPool.id }));
    setIsEdit(false);
    setErrors(undefined);
    setSelectedPool({ ...values, id: selectedPool.id });
  };
  const onDelete = () => {
    if (!selectedPool) return;
    dispatch(deletePoolSetting(selectedPool.id));
    const findIndex = poolSettings.findIndex((s) => s.id === selectedPool.id);
    const afterDelete = [
      ...poolSettings.slice(0, findIndex),
      ...poolSettings.slice(findIndex + 1),
    ];
    setSelectedPool(afterDelete[0]);
    setIsEdit(false);
  };

  const onCancel = () => {
    if (!poolFormForReset) return setSelectedPool(undefined);
    const isEmptyOption = (option?: string) => (!!option ? option + " " : "");
    setSelectedPool({
      id: poolFormForReset.id,
      hash: poolFormForReset.hash,
      name: poolFormForReset.name,
      login1: isEmptyOption(poolFormForReset.login1),
      login2: isEmptyOption(poolFormForReset.login2),
      login3: isEmptyOption(poolFormForReset.login3),
      address1: isEmptyOption(poolFormForReset.address1),
      address2: isEmptyOption(poolFormForReset.address2),
      address3: isEmptyOption(poolFormForReset.address3),
      password1: isEmptyOption(poolFormForReset.address3),
      password2: isEmptyOption(poolFormForReset.password2),
      password3: isEmptyOption(poolFormForReset.password3),
    });
    setIsEdit(false);
  };

  useEffect(() => {
    onFilterSettings();
  }, [filter]);

  useEffect(() => {
    setPoolSettings(allSettings);

    if (allSettings.length === 0) setIsEdit(false);
  }, [allSettings]);

  //saving reset pool
  useEffect(() => {
    setPoolFormForReset(selectedPool);
  }, [selectedPool]);

  return {
    isEdit,
    poolSettings,
    selectedPool,
    formRef,
    isCreatingNewPool,
    newPoolError,
    errors,

    onCreateNewPoolSetting,
    setFilter,
    setHashForCreate,
    onEditHandler,
    onSelectPoolSetting,
    onGetNewPoolName,
    onFormControl,
    setNewPoolError,
    onDelete,
    onCancel,
  };
};
