/*export enum RequestTypes {
  GET = "get",
  POST = "post",
  PUT = "put",
  DELETE = "delete"
}*/

export default interface BaseRequest {
  readonly url: string;
  // readonly type: RequestTypes;
  readonly params?: any;
}

export class GetRequest implements BaseRequest {
  readonly url : string;
  // readonly type = RequestTypes.GET;
  readonly params?: any;
  constructor(url : string, params? : any) {
    this.url = url;
    this.params = params;
  }
}
export class PostRequest implements BaseRequest {
  readonly url : string;
  // readonly type = RequestTypes.POST;
  readonly params: any;
  constructor(url : string, params : any) {
    this.url = url;
    this.params = params;
  }
}

export class PutRequest implements BaseRequest {
  readonly url : string;
  // readonly type = RequestTypes.PUT;
  readonly params: any;
  constructor(url : string, params : any) {
    this.url = url;
    this.params = params;
  }
}

export class DeleteRequest implements BaseRequest {
  readonly url : string;
  // readonly type = RequestTypes.DELETE;
  readonly params: any;
  constructor(url : string, params : any) {
    this.url = url;
    this.params = params;
  }
}