import updateServerTimeDelta from "@services/updateServerTimeDelta";
import { addTokenExpiresDays, getAuthToken } from "@store/auth/cookieStorage";
import qs from "qs";
import { BaseResponse, isErrorResponse } from "./responses/BaseResponse";
import { REACT_APP_BASE_URL } from "../../config";
import { dev } from "@utils/dev";
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
// import { serverErrors } from "../../i18n/serverErrors.messages";
import { Buffer } from "buffer";

export class ErrorData {
  public code: number;
  public message: string;

  constructor(code: number, message: string) {
    this.code = code;
    // this.message = code ? serverErrors(code) : message;
    this.message = message;
  }
}

export class UnknownError extends ErrorData {
  constructor() {
    super(-1, "Unknown error");
  }
}

function responseInterceptor(response: AxiosResponse<any>) {
  if (response.headers.date) {
    updateServerTimeDelta(response.headers.date);
  }
  if (response.data.Base64) {
    return {
      ...response,
      data: JSON.parse(Buffer.from(response.data.Base64, "base64").toString()),
    };
  }
  return response;
}

function requestInterceptor(config: AxiosRequestConfig) {
  const token = getAuthToken();
  if (token) {
    config.headers!["Authorization"] = `Bearer ${token}`;
    addTokenExpiresDays(2);
  } else {
    dev.log("no token!!");
  }
  if (config.method === "post" || config.method === "put") {
    if (!(config.data instanceof FormData)) {
      if (!(typeof config.data === "string")) {
        config.data = qs.stringify(config.data);
      }
      config.headers!["Content-Type"] = "application/x-www-form-urlencoded";
    }
  }
  return { ...config };
}

class HttpService {
  private _axios: AxiosInstance;

  constructor(baseURL: string) {
    this._axios = axios.create({
      baseURL: baseURL,
    });
    this._axios.interceptors.response.use(responseInterceptor);
    this._axios.interceptors.request.use(requestInterceptor);
    // this._axios.defaults.withCredentials = true;
  }

  private async call<T extends BaseResponse>(
    api: () => Promise<AxiosResponse<T>>
  ): Promise<T | ErrorData> {
    try {
      const response = await api();
      if (response.data.Status === "success") {
        return Promise.resolve(response.data);
      }
      if (isErrorResponse(response.data)) {
        return Promise.reject(
          new ErrorData(response.data.ErrCode, response.data.Message)
        );
      }
      return Promise.reject(new UnknownError());
    } catch (e) {
      // server error 404, 405, 500 etc
      if (e instanceof Error) {
        return Promise.reject(new ErrorData(0, e.message));
      }
      if (typeof e === "string") {
        return Promise.reject(new ErrorData(0, e));
      }
      return Promise.reject(new UnknownError());
    }
  }

  put = async <T extends BaseResponse>(
    URL: string,
    payload: any,
    abortSignal?: AbortSignal
  ): Promise<T | ErrorData> => {
    return this.call(() =>
      this._axios.put<T>(URL, payload, { signal: abortSignal })
    );
  };

  get = async <T extends BaseResponse>(
    URL: string,
    abortSignal?: AbortSignal
  ): Promise<T | ErrorData> => {
    return this.call(() => this._axios.get<T>(URL, { signal: abortSignal }));
  };

  post = async <T extends BaseResponse>(
    URL: string,
    payload: any,
    abortSignal?: AbortSignal
  ): Promise<T | ErrorData> => {
    return this.call(() =>
      this._axios.post<T>(URL, payload, { signal: abortSignal })
    );
  };

  delete = <T extends BaseResponse>(
    URL: string,
    abortSignal?: AbortSignal
  ): Promise<T | ErrorData> => {
    return this.call(() => this._axios.delete<T>(URL, { signal: abortSignal }));
  };
}

const httpService = new HttpService(REACT_APP_BASE_URL + "/api");
export default httpService;
