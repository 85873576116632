import { LogoffRequest } from "@services/auth";
import useApiRequests from "@services/http/useApiRequest";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { ROUTES_NAME_E } from "@utils/routesName";
import { useNavigate } from "react-router-dom";
import { logout as logoutDispatch } from "@store/auth/actions";
import { clearServerTime } from "@utils/serverTime";

interface IAccountPopoverControl {
  readonly isAdmin: boolean;
  readonly isLoadLogout: boolean;

  logout(): void;
}

export const useAccountPopoverControl = (): IAccountPopoverControl => {
  const auth = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  const requests = useApiRequests();
  const dispatch = useAppDispatch();

  const logout = async () => {
    navigate(ROUTES_NAME_E.HOME);
    await requests.enqueue(new LogoffRequest());
    dispatch(logoutDispatch());
    clearServerTime();
  };

  return {
    isAdmin: auth.isAdmin,
    isLoadLogout: requests.isLoading,

    logout,
  };
};
