import { SHOW_LOGS } from "../config";

export namespace dev {

  export function log(text : string, obj? : any) {
    if (SHOW_LOGS) {
      if (obj) {
        //console.trace();
        // console.log(new Date());
        console.log(text, obj);
      } else {
        console.log(text);
      }
    }
  }

  export function trace(text : string, obj? : any) {
    if (SHOW_LOGS) {
      if (obj) {
        //console.trace();
        console.trace(text, obj);
      } else {
        console.trace(text);
      }
    }
  }
  
  export function error(text : string, obj? : any) {
    if (SHOW_LOGS) {
      if (obj) {
        console.error(text, obj);
      } else {
        console.error(text);
      }
    }
  }
}
