import { toast } from "react-toastify";

interface ToastsI {
  error(s: string): void;
  success(s: string): void;
  dismis(): void;
}

export const useToasts = (): ToastsI => {
  // progressClassName: "toast--success",
  // icon: <Check style={{ height: "100%" }} />,

  const error = (msg: string) => {
    return toast.error(msg);
  };
  const success = (msg: string) => {
    return toast.success(msg, {
      progressClassName: "success__progress",
    });
  };

  const dismis = () => toast.dismiss();

  return {
    error,
    success,
    dismis,
  };
};
