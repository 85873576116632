import { ROUTES_NAME_E } from "@utils/routesName";
import { Link } from "react-router-dom";
import Icon from "../Icon/Icon";
import styles from "./MainLogo.module.scss";
import { MainLogoPropsI } from "./MainLogo.props";
import cn from "classnames";

import logo from "@assets/icons/logo.svg";

export default function MainLogo({ className }: MainLogoPropsI) {
  return (
    <Link to={ROUTES_NAME_E.HOME}>
      <Icon src={logo} className={cn(styles.logo, className)} />
    </Link>
  );
}
