import { SyncOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { ILoaderProps } from "./Loader.props";
import "antd/lib/spin/style/index.css";

export default function Loader({ size }: ILoaderProps) {
  return (
    <Spin
      indicator={
        <SyncOutlined
          spin
          style={{ fontSize: size, color: "lightgray", margin: "5px 0" }}
        />
      }
    />
  );
}
