import { useEffect } from "react";
import Footer from "./Layout/Footer/Footer";
import Header from "./Layout/Header/Header";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ROUTES_NAME_E } from "./utils/routesName";
import { SuspendComponent } from "./utils/SuspenseComponents";
import { ToastContainer } from "react-toastify";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import AuthStates from "@store/auth/AuthStates";
import { updateAuth } from "@store/auth/actions";
import http from "@services/http";
import { isTokenAlive } from "@store/auth/cookieStorage";
import useCurrentUser from "@utils/hooks/useCurrentUser";
import moment from "moment";

export enum ComponentsE {
  HOME = "Home",
  LOGIN = "Login",
  HOTEL = "Hotel",
  LISTINGS = "Listings",
  MESSENGER = "Messenger",
  REQUESTBOOK = "RequestBook",
  ACCOUNT = "Account",
  ERROR_404 = "Err404",
  POOL_SETTINGS = "PoolSettings",
  DASHBOARD = "Dashboard",
  CLIENTS = "Clients",
}

function App() {
  // const [locale, setLocale] = useState<LocaleLangE>(getLang());
  const auth = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  useEffect(() => {
    http.utils.getTime();
    dispatch(
      updateAuth({
        state: isTokenAlive() ? AuthStates.AUTHORIZED : AuthStates.UNAUTHORIZED,
        isAdmin: auth.isAdmin,
      })
    );
  }, []);

  useCurrentUser();

  //save locale
  // useEffect(() => {
  //   saveLang(locale);
  //   moment.locale(locale.toLocaleLowerCase());
  // }, [locale]);
  return (
    <div className='App'>
      <ToastContainer className={"toasts__global__class"} />

      <Router>
        <Header />
        <Routes>
          <Route
            path={ROUTES_NAME_E.HOME}
            element={SuspendComponent(ComponentsE.HOME)}
          />
          <Route
            path={ROUTES_NAME_E.LOGIN}
            element={SuspendComponent(ComponentsE.LOGIN)}
          />
          <Route
            path={ROUTES_NAME_E.HOTEL}
            element={SuspendComponent(ComponentsE.HOTEL)}
          />
          <Route
            path={ROUTES_NAME_E.LISTINGS}
            element={SuspendComponent(ComponentsE.LISTINGS)}
          />
          <Route
            path={ROUTES_NAME_E.MESSENGER}
            element={SuspendComponent(ComponentsE.MESSENGER)}
          />
          <Route
            path={ROUTES_NAME_E.REQUESTBOOK}
            element={SuspendComponent(ComponentsE.REQUESTBOOK)}
          />
          <Route
            path={ROUTES_NAME_E.ACCOUNT}
            element={SuspendComponent(ComponentsE.ACCOUNT)}
          />
          <Route
            path={ROUTES_NAME_E.LISTINGS}
            element={SuspendComponent(ComponentsE.LISTINGS)}
          />
          <Route
            path={ROUTES_NAME_E.POOL_SETTINGS}
            element={SuspendComponent(ComponentsE.POOL_SETTINGS)}
          />

          <Route
            path={ROUTES_NAME_E.LISTINGS}
            element={SuspendComponent(ComponentsE.LISTINGS)}
          />
          <Route
            path={ROUTES_NAME_E.DASHBOARD}
            element={SuspendComponent(ComponentsE.DASHBOARD)}
          />
          <Route
            path={ROUTES_NAME_E.CLIENTS}
            element={SuspendComponent(ComponentsE.CLIENTS)}
          />
          {/* все что не подходит под роутинг - перенаправляется на главную */}
          <Route path={"*"} element={SuspendComponent(ComponentsE.ERROR_404)} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
