import { lazy } from "react";

const Home = lazy(() => import("@components/pages/Home/Home"));
const Login = lazy(() => import("@components/pages/Login/Login"));
const Hotel = lazy(() => import("@components/pages/Hotel/Hotel"));
const Listings = lazy(() => import("@components/pages/Listings/Listings"));
const Messenger = lazy(() => import("@components/pages/Messanger/Messanger"));
const RequestBook = lazy(
  () => import("@components/pages/RequestBook/RequestBook")
);
const Account = lazy(() => import("@components/pages/Account/Account"));
const Err404 = lazy(() => import("@components/pages/Err404/Err404"));
const PoolSettings = lazy(
  () => import("@components/pages/PoolSettings/PoolSettings")
);
const Dashboard = lazy(() => import("@components/pages/Dashboard/Dashboard"));
const Clients = lazy(() => import("@components/pages/Clients/Clients"));

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Home,
  Login,
  Hotel,
  Listings,
  Messenger,
  RequestBook,
  Account,
  Err404,
  PoolSettings,
  Dashboard,
  Clients,
};
