import styles from "./AccountPopover.module.scss";
import cn from "classnames";
import Icon from "@components/global/Icon/Icon";
import { Popover } from "antd";
import { IAccountPopoverProps } from "./AccountPopover.props";
import Title from "@components/global/Title/Title";
import { Link } from "react-router-dom";
import { ROUTES_NAME_E } from "@utils/routesName";
import { useAccountPopoverControl } from "./useAccountPopover.control";
import { LogoutOutlined } from "@ant-design/icons";

import login from "@assets/icons/login.svg";
import Button from "@components/global/Button/Button";
import { SWITCH_NAV_MODE } from "../Header/useHeaderControl";

export default function AccountPopover({ email, mode }: IAccountPopoverProps) {
  const control = useAccountPopoverControl();
  const HOSTING_MODE = mode === SWITCH_NAV_MODE.HOSTING;
  const title = <Title className={styles.title}>{email}</Title>;

  const content = (
    <div className={styles.content}>
      <Link to={`account/${email}`} className={styles.link}>
        Account
      </Link>
      {HOSTING_MODE && (
        <Link to={ROUTES_NAME_E.CLIENTS} className={styles.link}>
          Clients
        </Link>
      )}
      <Link to={ROUTES_NAME_E.POOL_SETTINGS} className={styles.link}>
        Settings
      </Link>

      <Button
        primary
        className={cn(styles.logout_btn)}
        onClick={control.logout}
        loading={control.isLoadLogout}
      >
        Logout <LogoutOutlined className={styles.icon} />
      </Button>
    </div>
  );

  return (
    <Popover
      placement='bottomRight'
      title={title}
      content={content}
      trigger={"click"}
    >
      <Icon src={login} />
    </Popover>
  );
}
