import { PostRequest } from "./http/BaseRequest";
import { ServerResponse } from "./http/settings";
import { IPoolSetting } from "@store/poolSettings/actionTypes";

export interface SettingsResponseI extends ServerResponse {
  Record: IPoolSetting[];
}
/*
export const getAdminSettings = (): Promise<
  AxiosResponse<SettingsResponseI> | Error
> => {
  return service.api.post("/admin/miner/settings/list");
};

export const getUserSettings = (): Promise<
  AxiosResponse<SettingsResponseI> | Error
> => {
  return service.api.post("/user/miner/settings/list");
};

export const deletePoolSettings = (id: string): Promise<AxiosResponse<ServerResponse> | Error> => {
  return service.api.post("/settings/delete", { id });
};
*/

export class GetAdminSettingsRequest extends PostRequest {
  constructor() {
    super("/admin/miner/settings/list", {});
  }
}

export class GetUserSettingsRequest extends PostRequest {
  constructor() {
    super("/user/miner/settings/list", {});
  }
}

export class DeletePoolSettingsRequest extends PostRequest {
  constructor(id: string) {
    super("/settings/delete", { id });
  }
}
