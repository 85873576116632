import styles from "./Button.module.scss";
import cn from "classnames";
import { ButtonPropsI } from "./Button.props";
import Loader from "../Loader/Loader";
import { useRef } from "react";

export default function Button({
  className,
  children,
  type,
  active,
  primary,
  grey,
  loading,
  disabled,

  ...props
}: ButtonPropsI) {
  const btn = useRef<HTMLButtonElement>(null);

  return (
    <div
      className={cn(styles.wrapper, className, {
        [styles.primary]: primary,
        [styles.grey]: grey,
        [styles.active]: active,
        [styles.disabled]: disabled,
      })}
      onClick={() => btn.current?.click()}
    >
      {loading ? <Loader /> : <span className={styles.child}>{children}</span>}

      <button
        className={styles.btn}
        type={type}
        ref={btn}
        disabled={disabled || loading}
        {...props}
      />
    </div>
  );
}
