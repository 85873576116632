import {
  ACTION_COLOCATIONS_TYPES,
  ActionAddColocaiton,
  ActionBaseColocations,
  ActionDeleteColocaiton,
  ActionEditColocaitonAbout,
  ActionEditColocaitonAcceptedMiners,
  ActionEditColocaitonAdminIncome,
  ActionEditColocaitonCameras,
  ActionEditColocaitonContractLength,
  ActionEditColocaitonElecticityPrice,
  ActionEditColocaitonGreenEnergy,
  ActionEditColocaitonGuarantedUptime,
  ActionEditColocaitonImages,
  ActionEditColocaitonInsurance,
  ActionEditColocaitonLocation,
  ActionEditColocaitonMiningPlan,
  ActionEditColocaitonName,
  ActionEditColocaitonSecurity,
  ActionEditColocaitonStatus,
  ActionEditColocaitonType,
  ActionSetColocaitons,
  COLOCATION_STATUS,
  COLOCATION_TYPE,
  IColocation,
  INSSURANCE_ENUM,
} from "./actionsTypes";

import test1 from "@assets/test1.png";
import test2 from "@assets/test2.png";
import test3 from "@assets/test3.png";
import { dev } from "@utils/dev";

const mock: IColocation[] = [
  {
    id: "1",
    images: {
      cover: test1,
      sliders: [test1, test2, test3],
    },
    status: false,
    available_miners: [{ "Avalont 19": 10 }],
    country_code: "RU",
    lastModify: Date.now(),
    name: "Stallion Mining",
    about:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    type: COLOCATION_TYPE.COMPANY,
    location: {
      country: "Unated States",
      city: "New York",
    },
    mining_plan: [],
    admin_income: { percent: 10, admin_setting: "1" },
    electricity_price: [
      {
        "1-101 kWh": 0.1,
        discount: 0,
      },
      {
        "101-200 kWh": 0.09,
        discount: 5,
      },
    ],
    contract_length: {
      period: 1,
      renewal: 1,
    },
    guaranted_uptime: 99,
    accepted_miners: [],
    customs: {
      state: {
        customs: false,
        fee: 0,
      },
      additional_info: "Additional info inputed",
    },
    green_energy: {
      state: true,
    },
    cameras: {
      state: [
        {
          descr: "Camera 1",
          link: "111",
        },
        {
          descr: "Camera 2",
          link: "222",
        },
      ],
    },
    security: {
      state: ["Cameras", "Alarm"],
    },
    insurance: {
      state: INSSURANCE_ENUM.YES,
    },
  },
];

interface IState {
  colocations: IColocation[];
}

const initialState: IState = {
  colocations: mock,
};

const editColocationSetting = (
  newPayload: any,
  state: IState,
  type: keyof IColocation
) => {
  const index = state.colocations.findIndex((col) => col.id === newPayload.id);
  const changedColocation = {
    ...state.colocations[index],
    [type]: newPayload.value,
  };

  if (
    JSON.stringify(newPayload.value) ===
    JSON.stringify(state.colocations[index][type])
  ) {
    dev.log(`${type} is the SAME in reducer`);
    return state.colocations;
  }

  const colocations = [
    ...state.colocations.slice(0, index),
    changedColocation,
    ...state.colocations.slice(index + 1),
  ];
  return colocations;
};

const reducer = (
  state = initialState,
  action: ActionBaseColocations
): IState => {
  switch (action.type) {
    case ACTION_COLOCATIONS_TYPES.SET_COLOCATIONS:
      const colocations = (action as ActionSetColocaitons).payload;
      return {
        ...state,
        colocations,
      };
    case ACTION_COLOCATIONS_TYPES.DELETE_COLOCATION:
      const deleteId = (action as ActionDeleteColocaiton).payload;
      const filtered = state.colocations.filter((col) => col.id !== deleteId);
      return {
        ...state,
        colocations: filtered,
      };
    case ACTION_COLOCATIONS_TYPES.ADD_COLOCATION:
      const newColocation = (action as ActionAddColocaiton).payload;
      return {
        ...state,
        colocations: [newColocation, ...state.colocations],
      };
    case ACTION_COLOCATIONS_TYPES.EDIT_COLOCATION_STATUS:
      return {
        ...state,
        colocations: editColocationSetting(
          (action as ActionEditColocaitonStatus).payload,
          state,
          "status"
        ),
      };
    case ACTION_COLOCATIONS_TYPES.EDIT_COLOCATION_NAME:
      return {
        ...state,
        colocations: editColocationSetting(
          (action as ActionEditColocaitonName).payload,
          state,
          "name"
        ),
      };
    case ACTION_COLOCATIONS_TYPES.EDIT_COLOCATION_IMAGES:
      return {
        ...state,
        colocations: editColocationSetting(
          (action as ActionEditColocaitonImages).payload,
          state,
          "images"
        ),
      };
    case ACTION_COLOCATIONS_TYPES.EDIT_COLOCATION_ABOUT:
      return {
        ...state,
        colocations: editColocationSetting(
          (action as ActionEditColocaitonAbout).payload,
          state,
          "about"
        ),
      };

    case ACTION_COLOCATIONS_TYPES.EDIT_COLOCATION_TYPE:
      return {
        ...state,
        colocations: editColocationSetting(
          (action as ActionEditColocaitonType).payload,
          state,
          "type"
        ),
      };

    case ACTION_COLOCATIONS_TYPES.EDIT_COLOCATION_LOCATION:
      return {
        ...state,
        colocations: editColocationSetting(
          (action as ActionEditColocaitonLocation).payload,
          state,
          "location"
        ),
      };

    case ACTION_COLOCATIONS_TYPES.EDIT_COLOCATION_MINIMG_PLAN:
      return {
        ...state,
        colocations: editColocationSetting(
          (action as ActionEditColocaitonMiningPlan).payload,
          state,
          "mining_plan"
        ),
      };

    case ACTION_COLOCATIONS_TYPES.EDIT_COLOCATION_ADMIN_INCOME:
      return {
        ...state,
        colocations: editColocationSetting(
          (action as ActionEditColocaitonAdminIncome).payload,
          state,
          "admin_income"
        ),
      };

    case ACTION_COLOCATIONS_TYPES.EDIT_COLOCATION_ELECTRICITY_PRICE:
      return {
        ...state,
        colocations: editColocationSetting(
          (action as ActionEditColocaitonElecticityPrice).payload,
          state,
          "electricity_price"
        ),
      };

    case ACTION_COLOCATIONS_TYPES.EDIT_COLOCATION_CONTRACT_LENGTH:
      return {
        ...state,
        colocations: editColocationSetting(
          (action as ActionEditColocaitonContractLength).payload,
          state,
          "contract_length"
        ),
      };

    case ACTION_COLOCATIONS_TYPES.EDIT_COLOCATION_GUARANTED_UPTIME:
      return {
        ...state,
        colocations: editColocationSetting(
          (action as ActionEditColocaitonGuarantedUptime).payload,
          state,
          "guaranted_uptime"
        ),
      };

    case ACTION_COLOCATIONS_TYPES.EDIT_COLOCATION_ACCEPTED_MINERS:
      return {
        ...state,
        colocations: editColocationSetting(
          (action as ActionEditColocaitonAcceptedMiners).payload,
          state,
          "accepted_miners"
        ),
      };
    case ACTION_COLOCATIONS_TYPES.EDIT_COLOCATION_CUSTOMS:
      return {
        ...state,
        colocations: editColocationSetting(
          (action as ActionEditColocaitonAcceptedMiners).payload,
          state,
          "customs"
        ),
      };
    case ACTION_COLOCATIONS_TYPES.EDIT_COLOCATION_GREEN_ENERGY:
      return {
        ...state,
        colocations: editColocationSetting(
          (action as ActionEditColocaitonGreenEnergy).payload,
          state,
          "green_energy"
        ),
      };
    case ACTION_COLOCATIONS_TYPES.EDIT_COLOCATION_CAMERAS:
      return {
        ...state,
        colocations: editColocationSetting(
          (action as ActionEditColocaitonCameras).payload,
          state,
          "cameras"
        ),
      };
    case ACTION_COLOCATIONS_TYPES.EDIT_COLOCATION_SECURITY:
      return {
        ...state,
        colocations: editColocationSetting(
          (action as ActionEditColocaitonSecurity).payload,
          state,
          "security"
        ),
      };
    case ACTION_COLOCATIONS_TYPES.EDIT_COLOCATION_INSURANCE:
      return {
        ...state,
        colocations: editColocationSetting(
          (action as ActionEditColocaitonInsurance).payload,
          state,
          "insurance"
        ),
      };
    case ACTION_COLOCATIONS_TYPES.EDIT_COLOCATION_CUSTOM_LINK:
      return {
        ...state,
        colocations: editColocationSetting(
          (action as ActionEditColocaitonInsurance).payload,
          state,
          "customLink"
        ),
      };
    default:
      return state;
  }
};

export default reducer;
