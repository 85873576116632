// import { useIntl } from "react-intl";

export const makeNotifs = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  // const { formatMessage } = useIntl();

  const errors = {
    fill: "Please, fill in all fields",
    match: "Passwords must match",
    length: "The password length cannot be less than 6 characters",
    password: "Enter your own password",
    search_length: "Should not be less than 3 letters",
    add_pool_setting__check: "Address or login are not filled in",
    miner_plan__name: "Please, fill in name of the plan",
    miner_plan__length: "Plan name should be more than 3 letters",
    miner_plan__days: "Please, select at least 1 day",
    miner_plan__period_default: "You already have a period [00:00 - 23:59]",
    miner_plan__periods: "One or more periods intersect",
  };
  const notifs = {
    errors,
    ok: {
      success: "Success",
      check_email: "Check your email for confirmation link",
      user_created: "User was created",
      email__verified: "Email was verified",
      password__restored: "Password was restored",
      copied: "Copied",
      sort_saved: "Saved",
      sort_cleared: "Cleared",
    },
  };

  return notifs;
};
