import { AppDispatch } from "@store";
import {
  ActionAddPoolSetting,
  ActionDeletePoolSetting,
  ActionEditNamePoolSetting,
  ActionEditPoolSetting,
  ActionSetPoolSettings,
  ActionTypePoolSettings,
  IChangePoolNameParams,
  IPoolSetting,
} from "./actionTypes";
import { DEFAULT_POOL_ID } from "@components/pages/PoolSettings/usePoolSettings.control";

export function setPoolSettings(
  poolSettings: IPoolSetting[]
): ActionSetPoolSettings {
  return {
    type: ActionTypePoolSettings.SET_POOL_SETTINGS,
    payload: poolSettings.sort((a, b) => a.name.localeCompare(b.name)),
  };
}

export function deletePoolSetting(id: string): ActionDeletePoolSetting {
  return {
    type: ActionTypePoolSettings.DELETE_POOL_SETTING,
    payload: id,
  };
}

export function addPoolSetting(
  poolSetting: IPoolSetting
): ActionAddPoolSetting {
  return {
    type: ActionTypePoolSettings.ADD_POOL_SETTING,
    payload: poolSetting,
  };
}

export function addPoolAndDeleteDefault(poolSetting: IPoolSetting) {
  return (dispatch: AppDispatch) => {
    dispatch(deletePoolSetting(DEFAULT_POOL_ID));
    dispatch(addPoolSetting(poolSetting));
  };
}

export function editPoolSetting(
  poolSetting: IPoolSetting
): ActionEditPoolSetting {
  return {
    type: ActionTypePoolSettings.EDIT_POOL_SETTING,
    payload: poolSetting,
  };
}

export function editNamePoolSetting(
  pool: IChangePoolNameParams
): ActionEditNamePoolSetting {
  return {
    type: ActionTypePoolSettings.EDIT_POOL_NAME,
    payload: pool,
  };
}
