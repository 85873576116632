import { TitlePropsI } from "./Title.props";
import cn from "classnames";
import styles from "./Title.module.scss";

export default function Title({ className, children, ...props }: TitlePropsI) {
  return (
    <h4 className={cn(styles.h4, className)} {...props}>
      {children}
    </h4>
  );
}
