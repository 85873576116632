import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
} from "@reduxjs/toolkit";
import { Persistor, persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/es/storage";
import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2";
import { reducer as modals } from "redux-modal";
import me from "./me/reducer";
import auth from "./auth/reducer";
import poolSettings from "./poolSettings/reducer";
import colocations from "./colocations/reducer";
import minerAds from "./minerAds/reducer";

const rootReducer = combineReducers({
  modals,
  me,
  auth,
  poolSettings,
  colocations,
  minerAds,
});

const persistConfig = {
  key: "WATTLORD:$dfgrth46fdg(123sdf#345-+dLsdfOsdfd3)",
  storage,
  whitelist: ["auth"],
  version: 1,
  // migrate: createMigrate(migrations as unknown as MigrationManifest, {
  //   debug: false,
  // }),
  stateReconciler: autoMergeLevel2,
  // transforms: [startupConnectTransform],
};

export const store = configureStore({
  reducer: persistReducer<RootState>(persistConfig, rootReducer),
});
export const persistor: Persistor = persistStore(store);
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof rootReducer>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
