import { AppDispatch } from "@store";
import { clearMe, setMe } from "@store/me/actions";
import {
  ActionBaseAuth,
  ActionUpdateAuth,
  ActionTypesAuthE,
  IAuthorization,
} from "./actionTypes";
import { AccountRecord } from "@services/http/responses/AccountResponse";
import { clearAuth, setAuthData } from "./cookieStorage";
import AUTH_STATES from "./AuthStates";

export function updateAuth(data: IAuthorization): ActionUpdateAuth {
  return {
    type: ActionTypesAuthE.UPDATE_AUTH,
    payload: { ...data },
  };
}

export function logon(data: AccountRecord) {
  return async (dispatch: AppDispatch) => {
    setAuthData({
      Token: data.Token,
      TokenExpiresAt: data.TokenExpiresAt,
      TokenLifeTime: data.TokenLifeTime,
    });
    dispatch(
      updateAuth({
        state: AUTH_STATES.AUTHORIZED,
        isAdmin: data.account.is_admin ? data.account.is_admin : false,
      })
    );
    dispatch(setMe(data.account));
    // dispatch(setMiningPlans(data.plans || []));
    // dispatch(getAllTypes(data.types || []));
  };
}

export function logout() {
  return async (dispatch: AppDispatch) => {
    // if (handle) await logoff();
    clearAuth();
    dispatch(authLogout());
    dispatch(clearMe());
    // dispatch(clearUsers());
    // dispatch(clearMiningPlans());
    // dispatch(clearMinerTypes());
    // dispatch(cleanMinersTableState());
  };
}

export function authLogout(): ActionBaseAuth {
  return {
    type: ActionTypesAuthE.LOGOUT,
  };
}

// вызывается только из initStore
export function init(): ActionBaseAuth {
  return {
    type: ActionTypesAuthE.INIT,
  };
}
