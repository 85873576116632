import { TgAccountsI } from "@services/getTgAccounts";
import { IAccount } from "@services/http/responses/AccountResponse";
import { IS_DEBUG } from "../../config";
import {
  ActionBaseMe,
  ActionSetMe,
  ActionSetNotifsEmails,
  ActionSetPoolDivider,
  // ActionSetPoolSettings,
  ActionSetTgAccounts,
  ActionTypesMeE,
  ActionUpdateMe,
  PoolDivider,
  // IStoredSettings,
} from "./actionTypes";

export interface Me extends IAccount {
  // settings: IStoredSettings[];
  // poolDivider: PoolDivider[];
  // tgAccounts: TgAccountsI[];
}

// const mockUser: Me = {
//   about: "asdadad ad ad ad asdsdaadawda dasdawdsadw dasdawdsadwdawdsad ",
//   avatar: "",
//   created: 1652463832,
//   email: {
//     value: "admin@mail.ru",
//     is_private: false,
//   },
//   name: "Admin_Gina",
//   // notification_emails: [
//   //   {
//   //     email: "email1@mail.rr",
//   //     is_verified: false,
//   //   },
//   //   {
//   //     email: "email2@mail.rr",
//   //     is_verified: false,
//   //   },
//   // ],
//   owner: true,
//   // telegram: {
//   //   value: "@testTelegram",
//   //   is_private: false,
//   // },
//   telegram_uid: "uuid_telegram",
//   telegram_bot_link: "dfghdrtedfg",
//   telegram_bot_qrcode:
//     "iVBORw0KGgoAAAANSUhEUgAAAQAAAAEAAQMAAABmvDolAAAABlBMVEX///8AAABVwtN+AAACVUlEQVR42uyZQc7jIAyFX8SCJUfgKFxsFBL1YhyFI7BkgfJGNqmSv519Yf6yqNrm68LCfn528T3f8yvPQpIH0LDaBCwF8HsJ8m2aCKgAVoTmWGFyJKs/EAC4qQDPstokcdHkpQTlLdt4AOF2sgHybC/zAqxofifnBCSjLIEoYS4FNsfylnIfBrR4bWpur8Hk1aX6r+oeHOjHkiXWwLyQ8Me7nA4OSEbx6BIUREiDfuw1MgxwvlW1Z7+LDagwZSIANhsRUnkhuQlwOFb/eGbUDICGuVQ0t9kET6Yq7UDReYAzTBpuNjV/aJi4V/cAwMKsUbCs3RRQnU69WbUJAAlTyhlO/cPqEsXpkPeUmwBgcxtA7rUboKpCla/q/jwg8o7IJKHASB5pFDb/uepiAqB6Uy4TcbYDALFgHgDw0gkolwXmFZAulyTP0jCAdt5Nr0FMjtoDFlS/uYkA2EzKBCU3oJ2X3DWjLgkaH1iql+oOMqHos9STSUzEQEAX+ubEGnehj5L7j2vungAgTYGMUYcl+9wtAefbDmR8QP1DvEmQ3JO7CekIwNMeGJmlTI48XbFvL/5hbEDqgpRkImXuTlVamPwiTQVoTbNEiisuAd3b/1Da8QEZsRC0OAwPl6yupm7+4fPANXdDBhBd6kWBt7fBfGDg3LIGdZ0m6/5mKcFKhUwEdHlKemPSyp5hPoYC+mZP7+JcBb+k3DxAaog8XXGG4y2jJgIIt+tavkD9J35o1OcB/e/g1KG8qNpL2mMq4Nyywskw2F3xpprEiYDv+Z7/7PwNAAD//xVBQsYoQ2W/AAAAAElFTkSuQmCC",
//   updated: 1652463832,
//   settings: [
//     { name: "Pool Setting #1", id: "0", hash: AlgosE.SHA256 },
//     { name: "Pool Setting #2", id: "1", hash: AlgosE.SCRIPT },
//   ],
//   twofa: true,
//   //admin
//   is_admin: true,
//   tgAccounts: [
//     {
//       user_name: "telega1",
//       chat_id: 1,
//       extern_id: "adasdfsdf",
//     },
//     {
//       user_name: "telega2",
//       chat_id: 2,
//       extern_id: "sdvsdv-asda12",
//     },
//   ],
//   // summary_email: false,
//   // summary_telegram: true,
// };

const defaultMe = {
  address: "",
  about: "",
  avatar: "",
  bg: "",
  created: 0,
  email: {
    is_private: false,
    value: "",
  },
  name: "",
  // notification_emails: [],
  owner: false,
  // telegram: {
  //   is_private: false,
  //   value: "",
  // },
  telegram_uid: "",
  telegram_bot_link: "",
  telegram_bot_qrcode: "",
  updated: 0,
  // tgAccounts: [],
  // summary_email: false,
  // summary_telegram: false,
  twofa: false,
  socials: [],
};

// const initialState = IS_DEBUG ? mockUser : defaultMe;
const initialState = defaultMe;

const meReducer = (state: Me = initialState, action: ActionBaseMe) => {
  switch (action.type) {
    case ActionTypesMeE.SET_ME:
      const { accData } = (action as ActionSetMe).payload;
      return {
        ...state,
        ...accData,
      };
    case ActionTypesMeE.UPDATE_ME:
      const payload = (action as ActionUpdateMe).payload;
      return {
        ...state,
        ...payload.accData,
      };
    case ActionTypesMeE.CLEAR_ME:
      return {
        ...state,
        ...defaultMe,
      };
    // case ActionTypesMeE.SET_POOL_SETTINGS:
    //   const { poolSettings } = (action as ActionSetPoolSettings).payload;
    //   return {
    //     ...state,
    //     settings: poolSettings,
    //   };
    // case ActionTypesMeE.SET_POOL_DIVIDER:
    //   const { poolDivider } = (action as ActionSetPoolDivider).payload;
    //   return {
    //     ...state,
    //     poolDivider,
    //   };
    // case ActionTypesMeE.SET_NOTIFS_EMAILS:
    //   const { emails } = (action as ActionSetNotifsEmails).payload;
    //   return {
    //     ...state,
    //     notification_emails: emails,
    //   };
    // case ActionTypesMeE.SET_TG_ACCOUNTS:
    //   const { tgAccounts } = (action as ActionSetTgAccounts).payload;
    //   return {
    //     ...state,
    //     tgAccounts,
    //   };
    default:
      return state;
  }
};

export default meReducer;
