import AUTH_STATES from "./AuthStates";

export enum ActionTypesAuthE {
  INIT = "INIT",
  // SET_AUTH = "SET_AUTH",
  UPDATE_AUTH = "UPDATE_AUTH",
  LOGOUT = "LOGOUT",
  CLEAR_AUTH = "CLEAR_AUTH",
}

export interface ActionBaseAuth {
  type: ActionTypesAuthE;
  payload?: any;
}

export interface IAuthorization {
  state: AUTH_STATES;
  isAdmin: boolean;
}
/*
export interface ActionSetAuth extends ActionBaseAuth {
  payload: IAuthorization;
} */

export interface ActionUpdateAuth extends ActionBaseAuth {
  payload: IAuthorization;
}
