import httpService, { ErrorData } from "./HttpService";
import { DataResponse } from "./responses/BaseResponse";
/*
export default function getTime() {
  service.api.get("/time");
} */

export interface TimeResponse extends DataResponse<number> {

}

export function getTime() : Promise<TimeResponse | ErrorData> {
  return httpService.get("/time");
}

const utils = {
  getTime
};

export default utils;