import { useEffect, useState } from "react";

export default function useMediaQuery(minMax: "min" | "max", media: number): boolean {
  const mediaQuery = window.matchMedia(`(${minMax}-width:${media}px)`);
  const [isValid, setIsValid] = useState<boolean>(mediaQuery.matches);
  useEffect(() => {
    mediaQuery.addEventListener("change", (e) => {
      return setIsValid(e.matches);
    });
  }, [mediaQuery]);
  return isValid;
}
