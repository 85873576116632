import { MiningPlanOptionsI } from "@services/getMinerList";

export enum ActionTypesMiningPlansE {
  SET_MINING_PLANS = "SET_MINING_PLANS",
  UPDATING_MINING_PLANS = "UPDATING_MINING_PLANS",
  CLEAR_MINING_PLANS = "CLEAR_MINING_PLANS",
}

export interface ActionBaseMiningPlans {
  type: ActionTypesMiningPlansE;
}

export interface ActionSetMiningPlans extends ActionBaseMiningPlans {
  payload: {
    mining_plans: MiningPlanOptionsI[];
  };
}

export interface ActionUpdatingMiningPlans extends ActionBaseMiningPlans {
  payload: {
    updated_plans: MiningPlanOptionsI[];
  };
}
