import { useAppSelector } from "@store/hooks";
import { IPoolSetting } from "@store/poolSettings/actionTypes";

export const useSettings = (): IPoolSetting[] => {
  const settings = useAppSelector(
    (store) =>
      (store.poolSettings as { poolSettings: IPoolSetting[] }).poolSettings
  );
  return settings;
};

export const useSettingsNames = (): string[] => {
  const settings = useSettings();
  const names = settings.map((item) => item.name);
  return names;
};

export const getSettingName = (id: string, settings: IPoolSetting[]) => {
  const find = settings.find((item) => item.id === id);
  if (!find) return "";
  return find.name;
};

export const getSettingId = (name: string, settings: IPoolSetting[]) => {
  const find = settings.find((item) => item.name === name);
  if (!find) return "";
  return find.id;
};
