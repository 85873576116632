import { HashAlgosE } from "@utils/HashAlgos";

export type HashForPoolOnly = Omit<HashAlgosE, HashAlgosE.ALL>;

export interface IPoolSetting {
  readonly id: string;
  readonly name: string;
  readonly hash: HashForPoolOnly;
  readonly address1: string;
  readonly address2?: string;
  readonly address3?: string;
  readonly login1: string;
  readonly login2?: string;
  readonly login3?: string;
  readonly password1?: string;
  readonly password2?: string;
  readonly password3?: string;
}

export enum ActionTypePoolSettings {
  SET_POOL_SETTINGS = "SET_POOL_SETTINGS",
  DELETE_POOL_SETTING = "DELETE_POOL_SETTING",
  ADD_POOL_SETTING = "ADD_POOL_SETTING",
  EDIT_POOL_SETTING = "EDIT_POOL_SETTING",
  EDIT_POOL_NAME = "EDIT_POOL_NAME",
}

export interface ActionBasePoolSettings {
  type: ActionTypePoolSettings;
}

export interface ActionSetPoolSettings extends ActionBasePoolSettings {
  payload: IPoolSetting[];
}

export interface ActionDeletePoolSetting extends ActionBasePoolSettings {
  payload: string; // as "id" of PoolSetting
}

export interface ActionAddPoolSetting extends ActionBasePoolSettings {
  payload: IPoolSetting;
}

export interface ActionEditPoolSetting extends ActionBasePoolSettings {
  payload: IPoolSetting;
}

export interface IChangePoolNameParams {
  name: string;
  id: string;
}

export interface ActionEditNamePoolSetting extends ActionBasePoolSettings {
  payload: IChangePoolNameParams;
}
