import { LoginParams, RegisterParams } from "./dto";
import { PostRequest } from "../BaseRequest";

export class LogonRequest extends PostRequest {
  constructor(params: LoginParams) {
    super("/logon", params);
  }
}

export class RegisterRequest extends PostRequest {
  constructor(params: RegisterParams) {
    super("/user/register", params);
  }
}
class AccountRequestParams {
  email?: string;
}
export class AccountRequest extends PostRequest {
  constructor(params: AccountRequestParams) {
    super("/user/account", params);
  }
}
