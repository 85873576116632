import { ICameras } from "@components/global/CropHotelInformation/CropHotelInformation.props";

export enum COLOCATION_STATUS {
  AVAILABLE,
  UNAVAILABLE,
}

export interface IMachinesForSale {
  [key: string]: number;
}

export interface IColocationImagesSetting {
  readonly cover?: string;
  readonly sliders: string[];
}

export interface IConsumptionOption {
  readonly discount: number;
  readonly [key: string]: number;
}

export enum REVIEW_STARS {
  ONE = 1,
  TWO = 2,
  THREE = 3,
  FOUR = 4,
  FIVE = 5,
}

export interface IReviews {
  readonly reviews: number;
  readonly positive: number;
  readonly stars: REVIEW_STARS;
  //TODO интерфейс проработать с иконками
  readonly legal?: any;
}

export enum COLOCATION_TYPE {
  COMPANY = "company",
  PERSON = "person",
}

export interface IAdminIncome {
  percent: number;
  admin_setting: string;
}

export interface IContractLength {
  period: number; // month , 2 month and etc
  renewal: number; // Max price increase at the time of contract renewal
}

interface IAdditionalColocationSetting {
  additional_info?: string;
}

export interface ICustomsSetting extends IAdditionalColocationSetting {
  state: {
    customs: boolean;
    fee: number;
  };
}

export interface IGreenEnergySetting extends IAdditionalColocationSetting {
  state: boolean;
}

export interface ICamerasSetting extends IAdditionalColocationSetting {
  state: ICameras[];
}

export interface ISecuritySetting extends IAdditionalColocationSetting {
  state: ["Cameras", "Alarm"];
}

export enum INSSURANCE_ENUM {
  YES = "yes",
  NO = "no",
  NONO = "nono",
}

export interface IInsuranceSetting extends IAdditionalColocationSetting {
  state: INSSURANCE_ENUM;
}

export interface ILocationSetting {
  readonly country: string;
  readonly city: string;
}
export interface IColocation {
  readonly id: string;
  readonly images: IColocationImagesSetting;
  readonly status: boolean;
  readonly available_miners: IMachinesForSale[];
  readonly country_code: string;
  // readonly reviews: IReviews[];
  readonly lastModify: number;
  readonly name: string;
  readonly about: string;
  readonly type: COLOCATION_TYPE; // company | person
  readonly location: ILocationSetting;
  readonly mining_plan: any; //как в WM
  readonly admin_income: IAdminIncome;
  readonly electricity_price: IConsumptionOption[];
  readonly contract_length: IContractLength;
  readonly guaranted_uptime: number; // percent
  readonly accepted_miners: any; // пока не известно что это
  readonly customs: ICustomsSetting; // таможня
  readonly green_energy: IGreenEnergySetting;
  readonly cameras: ICamerasSetting;
  readonly security: ISecuritySetting;
  readonly insurance: IInsuranceSetting;
  readonly customLink?: string;
}

export enum ACTION_COLOCATIONS_TYPES {
  SET_COLOCATIONS = "SET_COLOCATIONS",
  DELETE_COLOCATION = "DELETE_COLOCATION",
  ADD_COLOCATION = "ADD_COLOCATION",
  // edit
  EDIT_COLOCATION_STATUS = "EDIT_COLOCATION_STATUS",
  EDIT_COLOCATION_NAME = "EDIT_COLOCATION_NAME",
  EDIT_COLOCATION_IMAGES = "EDIT_COLOCATION_IMAGES",
  EDIT_COLOCATION_ABOUT = "EDIT_COLOCATION_ABOUT",
  EDIT_COLOCATION_TYPE = "EDIT_COLOCATION_TYPE",
  EDIT_COLOCATION_LOCATION = "EDIT_COLOCATION_LOCATION",
  EDIT_COLOCATION_MINIMG_PLAN = "EDIT_COLOCATION_MINIMG_PLAN",
  EDIT_COLOCATION_ADMIN_INCOME = "EDIT_COLOCATION_ADMIN_INCOME",
  EDIT_COLOCATION_ELECTRICITY_PRICE = "EDIT_COLOCATION_ELECTRICITY_PRICE",
  EDIT_COLOCATION_CONTRACT_LENGTH = "EDIT_COLOCATION_CONTRACT_LENGTH",
  EDIT_COLOCATION_GUARANTED_UPTIME = "EDIT_COLOCATION_GUARANTED_UPTIME",
  EDIT_COLOCATION_ACCEPTED_MINERS = "EDIT_COLOCATION_ACCEPTED_MINERS",
  EDIT_COLOCATION_CUSTOMS = "EDIT_COLOCATION_CUSTOMS",
  EDIT_COLOCATION_GREEN_ENERGY = "EDIT_COLOCATION_GREEN_ENERGY",
  EDIT_COLOCATION_CAMERAS = "EDIT_COLOCATION_CAMERAS",
  EDIT_COLOCATION_SECURITY = "EDIT_COLOCATION_SECURITY",
  EDIT_COLOCATION_INSURANCE = "EDIT_COLOCATION_INSURANCE",
  EDIT_COLOCATION_CUSTOM_LINK = "EDIT_COLOCATION_CUSTOM_LINK",
}

export interface ActionBaseColocations {
  type: ACTION_COLOCATIONS_TYPES;
}

export interface ActionSetColocaitons extends ActionBaseColocations {
  payload: IColocation[];
}

export interface ActionDeleteColocaiton extends ActionBaseColocations {
  //id
  payload: string;
}

export interface ActionAddColocaiton extends ActionBaseColocations {
  payload: IColocation;
}

interface IEditPayload<T> {
  readonly id: string;
  readonly value: T;
}

export interface ActionEditColocaitonStatus extends ActionBaseColocations {
  payload: IEditPayload<boolean>;
}

export interface ActionEditColocaitonName extends ActionBaseColocations {
  payload: IEditPayload<string>;
}

export interface ActionEditColocaitonImages extends ActionBaseColocations {
  payload: IEditPayload<IColocationImagesSetting>;
}
export interface ActionEditColocaitonAbout extends ActionBaseColocations {
  payload: IEditPayload<string>;
}

export interface ActionEditColocaitonType extends ActionBaseColocations {
  payload: IEditPayload<COLOCATION_TYPE>;
}

export interface ActionEditColocaitonLocation extends ActionBaseColocations {
  payload: IEditPayload<ILocationSetting>;
}

//TODO
export interface ActionEditColocaitonMiningPlan extends ActionBaseColocations {
  payload: IEditPayload<any>;
}

export interface ActionEditColocaitonAdminIncome extends ActionBaseColocations {
  payload: IEditPayload<IAdminIncome>;
}

export interface ActionEditColocaitonElecticityPrice
  extends ActionBaseColocations {
  payload: IEditPayload<IConsumptionOption[]>;
}

export interface ActionEditColocaitonContractLength
  extends ActionBaseColocations {
  payload: IEditPayload<IContractLength>;
}

export interface ActionEditColocaitonGuarantedUptime
  extends ActionBaseColocations {
  payload: IEditPayload<number>;
}

export interface ActionEditColocaitonAcceptedMiners
  extends ActionBaseColocations {
  payload: IEditPayload<any>;
}

export interface ActionEditColocaitonCustom extends ActionBaseColocations {
  payload: IEditPayload<ICustomsSetting>;
}

export interface ActionEditColocaitonGreenEnergy extends ActionBaseColocations {
  payload: IEditPayload<IGreenEnergySetting>;
}
export interface ActionEditColocaitonCameras extends ActionBaseColocations {
  payload: IEditPayload<ICamerasSetting>;
}

export interface ActionEditColocaitonSecurity extends ActionBaseColocations {
  payload: IEditPayload<ISecuritySetting>;
}

export interface ActionEditColocaitonInsurance extends ActionBaseColocations {
  payload: IEditPayload<IInsuranceSetting>;
}

export interface ActionEditColocaitonCustomLink extends ActionBaseColocations {
  payload: {
    id: string;
    value?: string;
  };
}
