import { UserI } from "@services/getUsers";

export enum ActionTypesUsersE {
  SET_USERS = "SET_USERS",
  CLEAR_USERS = "CLEAR_USERS",
}

export interface ActionBaseUser {
  type: ActionTypesUsersE;
}

export interface ActionSetUsers extends ActionBaseUser {
  payload: {
    users: UserI[];
  };
}
