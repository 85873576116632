import { MiningPlanOptionsI } from "@services/getMinerList";

import {
  ActionBaseMiningPlans,
  ActionSetMiningPlans,
  ActionUpdatingMiningPlans,
  ActionTypesMiningPlansE,
} from "./actionTypes";

export function setMiningPlans(
  mining_plans: MiningPlanOptionsI[] = []
): ActionSetMiningPlans {
  return {
    type: ActionTypesMiningPlansE.SET_MINING_PLANS,
    payload: { mining_plans },
  };
}

export function clearMiningPlans(): ActionBaseMiningPlans {
  return {
    type: ActionTypesMiningPlansE.CLEAR_MINING_PLANS,
  };
}

export function updateMiningPlans(
  updated_plans: MiningPlanOptionsI[]
): ActionUpdatingMiningPlans {
  return {
    type: ActionTypesMiningPlansE.UPDATING_MINING_PLANS,
    payload: {
      updated_plans,
    },
  };
}
