import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { persistor, store } from "./store";
import { PersistGate } from "redux-persist/integration/react";

import "antd/dist/antd.css";
import "./styles/global.scss";
declare global {
  interface Window {
    SERVER_TIME_DELTA: number;
  }
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const start = async () => {
  return root.render(
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </React.StrictMode>
  );
};
start();

reportWebVitals();
