enum ServerErrorCodes {
  Unknown = -1,

  InternalError = 100,
  IncorrectParameters = 101,
  IncorrectParameterPassword = 102,
  IncorrectParameterNEmails = 103,
  IncorrectParameterTUser = 104,
  IncorrectParameterAbout = 105,
  PasswordsNotEqual = 106,
  UserAlreadyExists = 107,
  UserAttemptsLimit = 108,
  UserLocked = 109,
  PermissionDenied = 110,
  IncorrectVerifyCode = 111,
  VerifyTimeExceeded = 112,
  UserNotFound = 113,
  NeedLogon = 114,
  UserUnauth = 115,
  PoolSettingNotFound = 116,
  PoolSettingAlreadyExists = 117,
  BadPassword = 118,
  Need2FA = 119,
  UserAlreadyVerified = 120,
  PlanNotFound = 121,
  EmailNotConfirmed = 122,
  UserBanned = 123,
  NoWaitingVerifications = 124,
  DublicatedEmail = 125,
  LocationNotFound = 126,
  LocationAlreadyExists = 127,
  MinerNotFound = 128,
  MinerIsNotOffline = 129,
  UserHasMiners = 130,
  IncorrectParameterId = 131,
  IncorrectParameterEdit = 132,
  IncorrectParameterMinePrc = 133,
  IncorrectParameterHash = 134,
  IncorrectParameterSeparator = 135,
  IncorrectParameterLogin = 136,
  IncorrectParameterNote = 137,
  IncorrectParameterStart = 138,
  IncorrectParameterStop = 139,
  IncorrectParameterAdminText = 140,
  IncorrectParameterMinerType = 141,
  IncorrectParameterDfltSpeed = 142,
  IncorrectParameterAdmSetting = 143,
  IncorrectParameterSpeedDrop = 144,
  IncorrectParameterNotification = 145,
  IncorrectParameterUserId = 146,
  IncorrectParameterPhone = 147,
  IncorrectParameterName = 148,
  IncorrectParameterLocationId = 149,
  IncorrectParameterNotes = 150,
  IncorrectParameterAddress = 151,
  IncorrectParameterCoord = 152,
  IncorrectParameterEmail = 153,
  IncorrectParameterMode = 154,
  IncorrectParameterContact = 155,
  Incorrect2FACode = 156,
  TemporarilyDisabled = 157,
}

export default ServerErrorCodes;
