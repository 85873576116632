import { logout } from "@store/auth/actions";
import { useToasts } from "@utils/hooks/useToasts";
import { show } from "redux-modal";
import { useEffect, useState } from "react";
import { BaseResponse } from "./responses/BaseResponse";
import httpService, { ErrorData, UnknownError } from "./HttpService";
import ServerErrorCodes from "./ServerErrorCodes";
import { dev } from "@utils/dev";
import BaseRequest, {
  GetRequest,
  PostRequest,
  PutRequest,
} from "./BaseRequest";
import { LogoffRequest } from "@services/auth";
import { useAppDispatch } from "@store/hooks";
import { ModalsNameE } from "@utils/modalsNames";

export interface RequestOptions {
  onError?: (error: ErrorData) => void;
  onLoadingStatusChanged?: (isLoading: boolean) => void;
}

interface IApiRequest {
  isLoading: boolean;
  enqueue<R extends BaseResponse>(
    request: BaseRequest,
    options?: RequestOptions
  ): Promise<R | void>;
}

interface EnqueuedRequest {
  url: string;
  controller: AbortController;
}

export default function useApiRequests(): IApiRequest {
  const { error } = useToasts();
  const dispatch = useAppDispatch();
  const [isLoading, setLoading] = useState<boolean>(false);

  const queue: EnqueuedRequest[] = [];

  useEffect(() => {
    return () => {
      queue.forEach((request) => {
        dev.log(`abort request: ${request.url}`);
        request.controller.abort();
      });
    };
  }, []);

  function makeRequest<R extends BaseResponse>(
    request: BaseRequest,
    abortSignal?: AbortSignal
  ) {
    if (request instanceof GetRequest) {
      return httpService.get<R>(request.url, abortSignal);
    }
    if (request instanceof PostRequest) {
      return httpService.post<R>(request.url, request.params, abortSignal);
    }
    if (request instanceof PutRequest) {
      return httpService.put<R>(request.url, request.params, abortSignal);
    }
    return Promise.reject(new UnknownError());
    /*
    switch (request.type) {
      case RequestTypes.GET:
        return httpService.get<R>(request.url, abortSignal);
      case RequestTypes.POST:
        return httpService.post<R>(request.url, request.params, abortSignal);
      case RequestTypes.PUT:
        return httpService.put<R>(request.url, request.params, abortSignal);
      default:
        return Promise.reject(new UnknownError());
    }*/
  }

  function processError(e: any): void {
    console.log(e);
    if (e instanceof ErrorData) {
      switch (e.code) {
        case ServerErrorCodes.Need2FA:
          dispatch(show(ModalsNameE.USE_TWO_FA_MODAL));
          break;
        case ServerErrorCodes.NeedLogon:
          showErrorAndLogout("Your session is over");
          break;
        case ServerErrorCodes.PermissionDenied:
          showErrorAndLogout("Permission denied");
          break;
        default:
          error(e.message);
      }
    }
  }

  async function enqueue<R extends BaseResponse>(
    request: BaseRequest,
    options?: RequestOptions
  ): Promise<R | void> {
    setLoading(true);
    if (options && options.onLoadingStatusChanged) {
      options.onLoadingStatusChanged(true);
    }
    const controller = new AbortController();
    queue.push({ url: request.url, controller });
    try {
      return Promise.resolve(
        (await makeRequest(request, controller.signal)) as R
      );
    } catch (e: any) {
      if (e instanceof ErrorData) {
        if (options && options.onError) {
          options.onError(e);
        } else {
          processError(e);
        }
        return Promise.resolve();
      }
      return Promise.reject(e);
    } finally {
      setLoading(false);
      if (options && options.onLoadingStatusChanged) {
        options.onLoadingStatusChanged(false);
      }
    }
  }

  const showErrorAndLogout = (message: string) => {
    error(message);
    setTimeout(() => {
      enqueue(new LogoffRequest());
      dispatch(logout());
      window.location.href = "./"; //REACT_APP_BASE_URL ?? ""; TODO:
    }, 1000);
  };

  return {
    isLoading,
    enqueue,
  };
}
