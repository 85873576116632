export enum ActionTypesMinerTypesE {
  SET_MINER_TYPES = "SET_MINER_TYPES",
  SET_ALL_MINER_TYPES = "SET_ALL_MINER_TYPES",
  CLEAR_MINER_TYPES = "CLEAR_MINER_TYPES",
}

export interface ActionBaseMinerTypes {
  type: ActionTypesMinerTypesE;
}

export interface ActionSetMinerTypes extends ActionBaseMinerTypes {
  payload: {
    miner_types: string[];
  };
}
