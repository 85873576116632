
export type ResponseStatus = "success" | "error";

export interface BaseResponse {
  Status : ResponseStatus
}

export interface ErrorResponse extends BaseResponse {
	ErrCode : number,
	Message : string,
}

export interface DataResponse<T> extends BaseResponse {
  Record : T
}

export interface RecordsResponse<T> extends BaseResponse {
  Records : T[];
  Total: number;
}

export function isSuccessResponse(response : BaseResponse) : boolean {
  return response.Status === "success";
}


export function isErrorResponse(response : BaseResponse) : response is ErrorResponse {
  return (response as ErrorResponse).ErrCode !== undefined 
    && (response as ErrorResponse).Message !== undefined; 
}

export function isDataResponse<T>(response : BaseResponse) : response is DataResponse<T> {
  return (response as DataResponse<T>).Record !== undefined
}

export function hasError(response : BaseResponse) : boolean {
  return (isErrorResponse(response) && response.ErrCode > 0);
}