import MainLogo from "@components/global/MainLogo/MainLogo";
import { Link } from "react-router-dom";
import styles from "./Footer.module.scss";

import telegram from "@assets/icons/telegram.svg";
import twitter from "@assets/icons/twitter.svg";
import m from "@assets/icons/m.svg";
import linkdin from "@assets/icons/linkdin.svg";
import facebook from "@assets/icons/facebook.svg";
import inst from "@assets/icons/inst.svg";
import Icon from "@components/global/Icon/Icon";

const socials = [
  {
    link: "https://t.me/wattlordhotels",
    icon: telegram,
  },
  {
    link: "https://twitter.com/WattLord",
    icon: twitter,
  },
  {
    link: "https://wattlord.medium.com/",
    icon: m,
  },
  {
    link: "https://www.linkedin.com/company/80695426",
    icon: linkdin,
  },
  {
    link: "https://www.facebook.com/wattlord",
    icon: facebook,
  },
  {
    link: "https://www.instagram.com/watt_lord/",
    icon: inst,
  },
];

export default function Footer() {
  return (
    <footer className={styles.footer}>
      <div className={styles.nav_wrapper}>
        <MainLogo className={styles.logo} />
        <Link to={""} className={styles.link}>
          Terms & conditions
        </Link>
        <Link to={""} className={styles.link}>
          Privacy policy
        </Link>
        <Link to={""} className={styles.link}>
          Cookie policy
        </Link>
        <Link to={""} className={styles.link}>
          Refund policy
        </Link>
        <Link to={""} className={styles.link}>
          Acceptable use policy
        </Link>
        <Link to={""} className={styles.link}>
          Disclaimer
        </Link>
        <Link to={""} className={styles.link}>
          Contact us
        </Link>
        {/* <div className={styles.nav}>
          <Link to={""}>Terms & conditions</Link>
          <Link to={""}>Privacy policy</Link>
          <Link to={""}>Cookie policy</Link>
          <Link to={""}>Refund policy</Link>
          <Link to={""}>Acceptable use policy</Link>
          <Link to={""}>Disclaimer</Link>
          <Link to={""}>Contact us</Link>
        </div> */}
      </div>
      <div className={styles.socials_wrapper}>
        <div className={styles.socials}>
          {socials.map((s, i) => (
            <a href={s.link} target='_blank' rel='noreferrer' key={i}>
              <Icon src={s.icon} />
            </a>
          ))}
        </div>
        <div className={styles.cc}>© 2022 All rights reserved</div>
      </div>
    </footer>
  );
}
