import AnimationLoader from "@components/global/AnimationLoader/AnimationLoader";
import styles from "./PageLoader.module.scss";

export default function PageLoader() {
  return (
    <div className={styles.wrapper}>
      <AnimationLoader className={styles.loader} />
    </div>
  );
}
