import { IconPropsI } from "./Icon.props";
import cn from "classnames";
import styles from "./Icon.module.scss";
import { memo } from "react";

function Icon({ src, className, ...props }: IconPropsI) {
  return (
    <div className={cn(styles.icon, className)} {...props}>
      {/*  eslint-disable-next-line jsx-a11y/alt-text */}
      {/* <object type='image/svg+xml' data={src} /> */}
      <img src={src} alt='watt lord' />
    </div>
  );
}

export default memo(Icon);
