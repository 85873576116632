import { dev } from "@utils/dev";
import {
  ACTION_MINER_ADS_TYPES,
  ActionAddMinerAds,
  ActionDeleteMinerAds,
  ActionEditAdvertisedMinersMinerAds,
  ActionEditFacilityMinerAds,
  ActionEditPublicNotesMinerAds,
  ActionEditQuantityMinerAds,
  ActionEditStatusMinerAds,
  ActionMinerAdsBase,
  ActionSetMinersAds,
  IMinerAds,
} from "./actionTypes";

import m1 from "@assets/m1.png";
import m2 from "@assets/m2.png";
import m3 from "@assets/m3.png";

interface IState {
  minersAds: IMinerAds[];
}

const MOCK: IMinerAds[] = [
  {
    id: "1",
    facility: "Stallion Mining",
    status: false,
    // equipment: [
    //   {
    //     ["Antminer S19"]: 1,
    //     ["Antminer S19 pro"]: 2,
    //   },
    // ],
    price: 19000,
    images: { sliders: [m1, m2, m3] },
    quantity: 2,
    public_notes: "Test text",
    advertised_miners: [
      {
        miner_id: "AO1-132",
        id: "1",
        miner_type: "Antminer S19",
        miner_quantity: 1,
      },
      {
        miner_id: "AO1-133",
        id: "2",
        miner_type: "Antminer S19 pro",
        miner_quantity: 2,
      },
    ],
  },
];

const initialState: IState = {
  minersAds: MOCK,
};

const editMinerAdsSetting = (
  newPayload: any,
  state: IState,
  type: keyof IMinerAds
) => {
  const index = state.minersAds.findIndex((col) => col.id === newPayload.id);
  const changedColocation = {
    ...state.minersAds[index],
    [type]: newPayload.value,
  };

  if (
    JSON.stringify(newPayload.value) ===
    JSON.stringify(state.minersAds[index][type])
  ) {
    dev.log(`${type} is the SAME in reducer`, "MINER_ADS REDUCER");
    return state.minersAds;
  }

  const colocations = [
    ...state.minersAds.slice(0, index),
    changedColocation,
    ...state.minersAds.slice(index + 1),
  ];
  return colocations;
};

const reducer = (state = initialState, action: ActionMinerAdsBase): IState => {
  switch (action.type) {
    case ACTION_MINER_ADS_TYPES.SET_MINER_ADS:
      return {
        ...state,
        minersAds: (action as ActionSetMinersAds).payload,
      };

    case ACTION_MINER_ADS_TYPES.DELETE_MINER_ADS:
      const id = (action as ActionDeleteMinerAds).payload;
      return {
        ...state,
        minersAds: state.minersAds.filter((m) => m.id === id),
      };
    case ACTION_MINER_ADS_TYPES.ADD_MINER_ADS:
      const newMiner = (action as ActionAddMinerAds).payload;
      return {
        ...state,
        minersAds: [newMiner, ...state.minersAds],
      };
    case ACTION_MINER_ADS_TYPES.EDIT_MINER_ADS_STATUS:
      return {
        ...state,
        minersAds: editMinerAdsSetting(
          (action as ActionEditStatusMinerAds).payload,
          state,
          "status"
        ),
      };
    case ACTION_MINER_ADS_TYPES.EDIT_MINER_ADS_PHOTO:
      return {
        ...state,
        minersAds: editMinerAdsSetting(
          (action as ActionEditStatusMinerAds).payload,
          state,
          "images"
        ),
      };
    case ACTION_MINER_ADS_TYPES.EDIT_MINER_ADS_PRICE:
      return {
        ...state,
        minersAds: editMinerAdsSetting(
          (action as ActionEditStatusMinerAds).payload,
          state,
          "price"
        ),
      };
    case ACTION_MINER_ADS_TYPES.EDIT_MINER_ADS_QUANTITY:
      return {
        ...state,
        minersAds: editMinerAdsSetting(
          (action as ActionEditQuantityMinerAds).payload,
          state,
          "quantity"
        ),
      };
    case ACTION_MINER_ADS_TYPES.EDIT_MINER_ADS_FACILITY:
      return {
        ...state,
        minersAds: editMinerAdsSetting(
          (action as ActionEditFacilityMinerAds).payload,
          state,
          "facility"
        ),
      };
    case ACTION_MINER_ADS_TYPES.EDIT_MINER_ADS_PUBLIC_NOTES:
      return {
        ...state,
        minersAds: editMinerAdsSetting(
          (action as ActionEditPublicNotesMinerAds).payload,
          state,
          "public_notes"
        ),
      };
    case ACTION_MINER_ADS_TYPES.EDIT_MINER_ADS_ADVERTISED_MINERS:
      return {
        ...state,
        minersAds: editMinerAdsSetting(
          (action as ActionEditAdvertisedMinersMinerAds).payload,
          state,
          "advertised_miners"
        ),
      };
    default:
      return state;
  }
};

export default reducer;
