import {
  IColocationImagesSetting,
  IMachinesForSale,
} from "@store/colocations/actionsTypes";

export enum ACTION_MINER_ADS_TYPES {
  SET_MINER_ADS = "SET_MINER_ADS",
  DELETE_MINER_ADS = "DELETE_MINER_ADS",
  ADD_MINER_ADS = "ADD_MINER_ADS",
  // edit
  EDIT_MINER_ADS_STATUS = "EDIT_MINER_ADS_STATUS",
  EDIT_MINER_ADS_PHOTO = "EDIT_MINER_ADS_PHOTO",
  EDIT_MINER_ADS_PRICE = "EDIT_MINER_ADS_PRICE",
  EDIT_MINER_ADS_QUANTITY = "EDIT_MINER_ADS_QUANTITY",
  EDIT_MINER_ADS_FACILITY = "EDIT_MINER_ADS_FACILITY",
  EDIT_MINER_ADS_PUBLIC_NOTES = "EDIT_MINER_ADS_PUBLIC_NOTES",
  EDIT_MINER_ADS_ADVERTISED_MINERS = "EDIT_MINER_ADS_ADVERTISED_MINERS",
}

export interface ActionMinerAdsBase {
  type: ACTION_MINER_ADS_TYPES;
}

export interface IAdvertisedMiner {
  readonly miner_id: string;
  readonly id: string;
  readonly miner_type: string;
  readonly miner_quantity: number;
}

export interface IMinerAdsImages extends IColocationImagesSetting {}

export interface IMinerAds {
  readonly id: string;
  readonly facility: string;
  readonly status: boolean;
  // readonly equipment: IMachinesForSale[];
  readonly price: number;
  readonly images: IMinerAdsImages;
  readonly quantity: number;
  readonly public_notes: string;
  readonly advertised_miners: IAdvertisedMiner[];
}

export interface IEditPayload<T> {
  readonly id: string;
  readonly value: T;
}

export interface ActionSetMinersAds extends ActionMinerAdsBase {
  payload: IMinerAds[];
}

export interface ActionDeleteMinerAds extends ActionMinerAdsBase {
  payload: string;
}

export interface ActionAddMinerAds extends ActionMinerAdsBase {
  payload: IMinerAds;
}

export interface ActionEditStatusMinerAds extends ActionMinerAdsBase {
  payload: IEditPayload<boolean>;
}

export interface ActionEditPhotosMinerAds extends ActionMinerAdsBase {
  payload: IEditPayload<IMinerAdsImages>;
}

export interface ActionEditPriceMinerAds extends ActionMinerAdsBase {
  payload: IEditPayload<number>;
}

export interface ActionEditQuantityMinerAds extends ActionMinerAdsBase {
  payload: IEditPayload<number>;
}

export interface ActionEditFacilityMinerAds extends ActionMinerAdsBase {
  payload: IEditPayload<string>;
}

export interface ActionEditPublicNotesMinerAds extends ActionMinerAdsBase {
  payload: IEditPayload<string>;
}

export interface ActionEditAdvertisedMinersMinerAds extends ActionMinerAdsBase {
  payload: IEditPayload<IAdvertisedMiner[]>;
}
