import moment from "moment";

export default function updateServerTimeDelta(headerDate : string) {
  // headerDate format is 'Tue, 06 Sep 2022 15:20:26 GMT'
  const serverUtc = moment.utc(headerDate);
  const localUtc = moment.utc(new Date().getTime());
  const diffInMillis = (serverUtc.valueOf() - localUtc.valueOf());
  // console.log("Server", serverUtc.toString())
  // console.log("Local", localUtc.toString())
  // console.log('SERVER_TIME_DELTA', diffInMillis);
  window.SERVER_TIME_DELTA = diffInMillis; // Math.abs(diffInSec) > 30 ? diffInSec : 0;
}