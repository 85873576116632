import {
  ActionBaseAuth,
  ActionTypesAuthE,
  ActionUpdateAuth,
  IAuthorization,
} from "./actionTypes";
import AUTH_STATES from "./AuthStates";
import { isTokenAlive } from "./cookieStorage";

const InitialState: IAuthorization = {
  state: AUTH_STATES.UNAUTHORIZED, //isTokenAlive() ? AuthStates.AUTHORIZED : AuthStates.UNAUTHORIZED,
  isAdmin: false,
};

const initialState: IAuthorization = { ...InitialState };

const auth = (state = initialState, action: ActionBaseAuth) => {
  switch (action.type) {
    case ActionTypesAuthE.INIT:
      return state;
    case ActionTypesAuthE.UPDATE_AUTH:
      return { ...(action as ActionUpdateAuth).payload };
    case ActionTypesAuthE.LOGOUT:
      return { ...InitialState };
    default:
      return state;
  }
};

export default auth;
