import AuthStates from "@store/auth/AuthStates";
import { useAppSelector } from "@store/hooks";
import { Me } from "@store/me/reducer";
import { useMe } from "@utils/hooks/useMe";
import { ROUTES_NAME_E } from "@utils/routesName";
import { useState } from "react";

export enum SWITCH_NAV_MODE {
  CLIENT = "Client",
  HOSTING = "Hosting",
}

interface HeaderControlI {
  readonly isLogon: boolean;
  readonly isAdmin: boolean;
  readonly nav: SWITCH_NAV_MODE;
  readonly me?: Me;
  readonly isShowAcc: boolean;
  readonly links: { name: string; to: ROUTES_NAME_E }[];

  onSwitch(v: boolean): void;
  handleShowAcc(): void;
}

const loginLinks = {
  [SWITCH_NAV_MODE.CLIENT]: [
    {
      name: "Colocations",
      to: ROUTES_NAME_E.HOME,
    },
    {
      name: "Dashboard",
      to: ROUTES_NAME_E.DASHBOARD,
    },
    {
      name: "Messenger",
      // to: "",
      to: ROUTES_NAME_E.MESSENGER,
    },
  ],
  [SWITCH_NAV_MODE.HOSTING]: [
    {
      name: "Messenger",
      to: ROUTES_NAME_E.MESSENGER,
      // to: "",
    },
    {
      name: "Dashboard",
      to: ROUTES_NAME_E.DASHBOARD,
    },
    {
      name: "Listings",
      to: ROUTES_NAME_E.LISTINGS,
    },
  ],
};

const linksWithoutLogin = [
  {
    name: "Colocations",
    to: ROUTES_NAME_E.HOME,
  },
  {
    name: "Messenger",
    // to: "",
    to: ROUTES_NAME_E.MESSENGER,
  },
];

export const useHeaderControl = (): HeaderControlI => {
  const auth = useAppSelector((state) => state.auth);
  const me = useMe();

  const [nav, setNav] = useState<SWITCH_NAV_MODE>(SWITCH_NAV_MODE.CLIENT);
  const [isShowAcc, setIsShowAcc] = useState<boolean>(false);

  const handleShowAcc = (b?: boolean) => {
    if (typeof b !== "undefined") return setIsShowAcc(b);
    setIsShowAcc(!isShowAcc);
  };

  const onSwitch = (v: boolean) => {
    if (v) {
      setNav(SWITCH_NAV_MODE.HOSTING);
    } else {
      setNav(SWITCH_NAV_MODE.CLIENT);
    }
  };
  const isLogon = auth.state === AuthStates.AUTHORIZED;
  return {
    isLogon,
    isAdmin: auth.isAdmin,
    nav,
    me,
    isShowAcc,
    links: isLogon ? loginLinks[nav] : linksWithoutLogin,

    onSwitch,
    handleShowAcc,
  };
};
