import { TokenData } from '@services/http/responses/AccountResponse';
import Cookies from 'universal-cookie';

const KEY_TOKEN = 'token';
const KEY_EXPIRES_AT = 'expiresAt';

export function setAuthData({ Token : token, TokenLifeTime : expiresIn } : TokenData) : void {
  const cookies = new Cookies();
  const cookieExpires = new Date(Date.now() + 30 * 24 * 60 * 60 * 1000);
  const expiresAt = Date.now() + (expiresIn * 1000);
  cookies.set(KEY_EXPIRES_AT, expiresAt, { path: '/', expires: cookieExpires });
  cookies.set(KEY_TOKEN, token, { path: '/', expires: cookieExpires });
}

export function getAuthToken() : string | null {
  const cookies = new Cookies();
  const expiresAt = cookies.get(KEY_EXPIRES_AT);
  if (expiresAt && Date.now() < expiresAt) {
    return cookies.get(KEY_TOKEN);
  }
  return null;
}

export function clearAuth() {
  const cookies = new Cookies();
  cookies.remove(KEY_TOKEN);
  cookies.remove(KEY_EXPIRES_AT);
  cookies.remove("watt");
}

export function isTokenAlive() : boolean {
  const cookies = new Cookies();
  const expiresAt = cookies.get(KEY_EXPIRES_AT);
  return (expiresAt && Date.now() < expiresAt) && cookies.get(KEY_TOKEN);
}

export function addTokenExpiresDays(days : number) {
  const cookies = new Cookies();
  const daysInMillis = days * 24 * 60 * 60 * 1000;
  const expiresAt = Date.now() + daysInMillis;
  const cookieExpires = new Date(expiresAt);
  cookies.set(KEY_EXPIRES_AT, expiresAt, { path: '/', expires: cookieExpires });
}