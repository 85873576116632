import { useEffect } from "react";
import {
  GetAdminSettingsRequest,
  GetUserSettingsRequest,
  SettingsResponseI,
} from "@services/getSettings";
import {
  GetTgAccountsRequest,
  TgAccountsResponseI,
} from "@services/getTgAccounts";
import { AllUsersResponseI, GetAllUsersRequest } from "@services/getUsers";
import { AccountRequest } from "@services/http/auth";
import { LoginResponse } from "@services/http/auth/dto";
import useApiRequests from "@services/http/useApiRequest";
import { logout, updateAuth } from "@store/auth/actions";
import AuthStates from "@store/auth/AuthStates";
import { isTokenAlive } from "@store/auth/cookieStorage";
import { updateMe } from "@store/me/actions";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { getAllTypes } from "@store/minerTypes/actions";
import { setMiningPlans } from "@store/miningPlans/actions";
import { setUsers } from "@store/users/actions";
import { IPoolSetting } from "@store/poolSettings/actionTypes";
import { setPoolSettings } from "@store/poolSettings/actions";

export default function useCurrentUser() {
  const dispatch = useAppDispatch();
  const auth = useAppSelector((state) => state.auth);
  const requests = useApiRequests();

  useEffect(() => {
    if (auth.state === AuthStates.AUTHORIZED) {
      updateUser();
    }
  }, []);

  async function updateUser() {
    const response = await requests.enqueue<LoginResponse>(
      new AccountRequest({})
    );
    if (!response || !response.Record || !response.Record.account) {
      dispatch(logout());
      return;
    }
    const { account, types, plans } = response.Record;
    dispatch(
      updateAuth({
        state: isTokenAlive() ? AuthStates.AUTHORIZED : AuthStates.UNAUTHORIZED,
        isAdmin: account.is_admin || false,
      })
    );
    dispatch(updateMe(account));
    /*
    // обязательно сначала получаем данные по аккаунту
    // возвращаем данные аккаунта на случай дальнейших вызовов апишек
    const accountAfter = await new Promise<IAccount>((resolve) => {
      dispatch(updateMe(account));
      resolve(account);
    });*/
    //update settings
    updateSettings(account.is_admin || false);
    // update all users
    if (account.is_admin) updateAllUsers();
    //other updates
    dispatch(getAllTypes(types || []));
    dispatch(setMiningPlans(plans || []));
    // обновляем телеграмм аккаунты подписанные
    // const tgAccResponse = await requests.enqueue<TgAccountsResponseI>(
    //   new GetTgAccountsRequest()
    // );
    // dispatch(setTgAccounts(tgAccResponse?.Record || []));
  }

  async function updateSettings(isAdmin: boolean): Promise<void> {
    const request: GetAdminSettingsRequest | GetUserSettingsRequest = isAdmin
      ? new GetAdminSettingsRequest()
      : new GetUserSettingsRequest();
    const settings = await requests.enqueue<SettingsResponseI>(request);
    if (!settings) return;

    dispatch(setPoolSettings(settings.Record));
    // dispatch(setPoolDivider(d));
  }

  async function updateAllUsers(): Promise<void> {
    const users = await requests.enqueue<AllUsersResponseI>(
      new GetAllUsersRequest()
    );
    if (!users) return;
    dispatch(setUsers(users.Record));
  }
}
